import {mapGetters, mapMutations} from 'vuex';
import {bowl, getBatsmanPerformance, initScoring, undo} from "@/services/launchMatch/Scoring";
import {useToast} from "vue-toastification";
import {loadStrikee, updateBowlingStatus} from "@/services/launchMatch/StartInning";

export const Scoring = {
    data() {
        return {
            toast: useToast(),
            undoCount: 0,
            loadScore: false,
            wicket_type_id: 0,
            showFielder: false,
            completedInnings: [],
            results: [],
            outPlayerId: 0,
        }
    },
    methods: {

        initScoring() {
            // let data = {
            //   inning_id: this.storedInning.id,
            // }

            this.loadScore = true;


            initScoring(this.storedInning.id, this.storedInning.fixture_id).then(response => {
                this.loadScore = false;

                this.lastBowl = response.data.last_bowl;

                this.lastOver = response.data.last_over;
                this.recentRuns = response.data.recent_runs;

                this.totalInningRuns = response.data.totalInningRuns;
                this.totalInningWickets = response.data.totalInningWickets;

                this.lastScoreId = response.data.lastScoreId


                this.completedInnings = response.data.completedInnings;
                this.results = response.data.result;


            }, error => {

                this.toast.error(error.response.data.message);
                this.lastBowl = 0;
                this.lastOver = 0;
                this.recentRuns = [];
                this.totalInningRuns = 0;
                this.totalInningWickets = 0;
                this.loadScore = false;

            });


            this.loadStrike(this.storedInning.id, this.selectedBowler.id);

        },

        loadStrike(inning_id, bowler_id) {

            let bowlers = [];

            for (let i = 0; i < this.bowler.length; i++) {
                bowlers.push(this.bowler[i].id);
            }

            loadStrikee(inning_id, bowler_id, bowlers).then(response => {

                this.strikers = response.data.strikers;
                this.batsmenPerformance = response.data.batsmenPerformance;
                this.bowlerPerformance = response.data.bowlerPerformance;

                this.batsmenLegalRunsScored = response.data.batsmenLegalRunsScored;
                this.batsmenFacedLegalBalls = response.data.batsmenFacedLegalBalls;
                this.bowlerGivenRuns = response.data.bowlerGivenRuns;
                this.bowlerLegalBallsBowled = response.data.bowlerLegalBallsBowled;
                this.legalBallsBowled = response.data.legalBallsBowled;
                this.bowlerLegalRunsGiven = response.data.bowlerLegalRunsGiven;

            }, error => {
                this.toast.error(error.response.message)

                this.strikers = []
                this.batsmenPerformance = [];
                this.bowlerPerformance = [];

                this.batsmenLegalRunsScored = 0;
                this.batsmenFacedLegalBalls = 0;
                this.bowlerGivenRuns = [];
                this.bowlerLegalBallsBowled = 0;
                this.legalBallsBowled = 0;
            });


        },

        bowl(data) {


            this.loadScore = true;

            data.inning_id = this.storedInning.id;
            data.bowler_id = this.selectedBowler.id;
            data.bowlers = [];

            for (let i = 0; i < this.bowler.length; i++) {
                data.bowlers.push(this.bowler[i].id);
            }

            if (data.wicket_type_id > 0) {

                if (data.wicket_type_id === 1
                    || data.wicket_type_id === 2
                    || data.wicket_type_id === 3
                    || data.wicket_type_id === 7
                ) {
                    data.over = this.lastBowl < 6 ? this.lastOver === 0 ? 1 : this.lastOver : this.lastOver + 1;
                    data.bowl = this.lastBowl < 6 ? this.lastBowl + 1 : 1;

                } else {
                    data.fielder_id = this.fielder_id;
                    data.over = this.lastOver;
                    data.bowl = this.lastBowl;
                }


            } else if (
                data.extra_runs_type_id === 3
                || data.extra_runs_type_id === 4
                || data.extra_runs_type_id === 5
                || data.extra_runs_type_id === 6
                || data.extra_runs_type_id === 7
                || data.extra_runs_type_id === 8
                || data.extra_runs_type_id === 9
            ) {

                if (this.lastBowl === 6) {
                    data.over = this.lastOver + 1;
                    data.bowl = 0;
                } else {
                    data.over = this.lastOver;
                    data.bowl = 0;
                }


            } else {
                data.over = this.lastBowl < 6 ? this.lastOver === 0 ? 1 : this.lastOver : this.lastOver + 1;
                data.bowl = this.lastBowl < 6 ? this.lastBowl + 1 : 1;
            }


            for (let i = 0; i < this.strikers.length; i++) {
                if (this.strikers[i].striker === 1 && this.strikers[i].player_playing_status_id === 1) {
                    data.striker_id = this.strikers[i].player_id;
                }
            }

            this.toast.info('processing...')

            bowl(data).then(response => {

                this.undoCount = 0;
                this.toast.clear()
                this.lastOver = response.data.last_over;
                this.lastBowl = response.data.last_bowl;
                this.recentRuns = response.data.recent_runs;

                this.lastScoreId = response.data.lastScoreId;


                let runs = data.runs;

                if (data.extra_runs_type_id === 1
                    || data.extra_runs_type_id === 2) {
                    runs = data.extra_runs;
                }
                if (
                    data.extra_runs_type_id === 3
                    || data.extra_runs_type_id === 5
                    || data.extra_runs_type_id === 6) {
                    runs = data.extra_runs - 1;
                }


                //changing strike condition
                let duringOver = (
                    runs === 1
                    || runs === 3
                    || runs === 5
                    || runs === 7
                    || runs === 9
                );

                // changing strike condition
                let lastBallOfOver = (
                    runs === 0
                    || runs === 2
                    || runs === 4
                    || runs === 6
                    || runs === 8
                    || runs === 10
                );


                //changing strike during over
                if (data.bowl !== 0 && data.bowl <= 5) {
                    if (duringOver) {
                        this.changeStrike();
                    }
                } else if (data.bowl !== 0 && data.bowl === 6) {
                    // changing strike on last ball of the over

                    this.changeBowler();

                    if (lastBallOfOver) {
                        this.changeStrike();
                    }



                } else if (data.bowl === 0) {
                    // changing strike during over when illegal delivers
                    if (duringOver) {
                        this.changeStrike();
                    }

                }


                if (data.runs_type === 1 || data.extra_runs_type_id === 1 || data.extra_runs_type_id === 2) {
                    if (this.lastBowl === 6) {
                        this.toast.error('New Over Started!');
                        this.bowler = {};
                    }


                }
                this.loadStrike(this.storedInning.id, this.selectedBowler.id);
                this.initScoring();

            }, error => {
                this.toast.clear();
                this.toast.error(error.response.data.message);
                this.loadScore = false;
            });

            this.loadScore = false;
        },

        changeBowler() {
            this.toast.info('Changing Bowler...', {
                duration: 30000,
            })
            let data = {
                inning_id: this.storedInning.id,
                bowler_id: this.nonSelectedBowler.id
            }
            updateBowlingStatus(data).then((response) => {
                this.bowler = response.data.bowler;
                this.toast.clear();
            }, error => {
                this.toast.clear();
                this.toast.error(error)
            });
        },

        wicketTaken(data) {

            data.bowlers = [];

            for (let i = 0; i < this.bowler.length; i++) {
                data.bowlers.push(this.bowler[i].id);
            }

            if (this.outPlayerId > 0) {
                data.out_player_id = this.outPlayerId;
            } else {
                data.out_player_id = 0;
            }

            if (data.wicket_type_id > 0) {

                data.inning_id = this.storedInning.id;
                data.bowler_id = this.selectedBowler.id

                for (let i = 0; i < this.strikers.length; i++) {
                    if (this.strikers[i].striker === 1) {
                        data.striker_id = this.strikers[i].player_id;
                    }
                }


                this.wicket_type_id = data.wicket_type_id;

                if (
                    data.wicket_type_id === 1
                    || data.wicket_type_id === 2
                    || data.wicket_type_id === 3
                    || data.wicket_type_id === 4
                    || data.wicket_type_id === 8
                    || data.wicket_type_id === 10
                ) {
                    data.over = this.lastBowl < 6 ? this.lastOver === 0 ? 1 : this.lastOver : this.lastOver + 1;
                    data.bowl = this.lastBowl < 6 ? this.lastBowl + 1 : 1;
                } else {
                    data.over = this.lastOver;
                    data.bowl = 0;
                }

                if (data.wicket_type_id !== 1 || data.wicket_type_id !== 4) {
                    // data.fielder_id = 0;
                }

                if (data.wicket_type_id === 4 || data.wicket_type_id === 10) {
                    data.last_score_id = this.lastScoreId;
                }

                if (data.wicket_type_id === 5
                    || data.wicket_type_id === 6) {
                    data.extra_runs = 0;
                    data.extra_runs_type_id = 0;

                    this.$swal({

                        title: 'Wide Ball Out',

                        text: "Is it a wide Delivery",

                        type: 'info',
                        icon: 'info',

                        showCancelButton: true,

                        confirmButtonColor: '#d33',

                        cancelButtonColor: '#3085d6',

                        confirmButtonText: 'Yes',
                        cancelButtonText: 'No'

                    }).then((result) => {

                        if (result.isConfirmed) {


                            data.extra_runs = 1;
                            data.extra_runs_type_id = 3;

                            data.over = this.lastOver;
                            data.bowl = 0;

                            this.storeWicketTaken(data);
                            this.outPlayerId = 0;

                        } else {

                            data.over = this.lastBowl < 6 ? this.lastOver === 0 ? 1 : this.lastOver : this.lastOver + 1;
                            data.bowl = this.lastBowl < 6 ? this.lastBowl + 1 : 1;

                            this.storeWicketTaken(data);
                        }

                    });
                } else {
                    this.storeWicketTaken(data);
                }


            }


        },

        storeWicketTaken(data) {

            console.log(data);


            if (data.wicket_type_id === 4 && this.outPlayerId === 0) {

                this.$swal({

                    title: 'Select Batsman',

                    text: "Please Select Crease Batsmen to Out",

                    type: 'info'
                });

            } else {
                this.toast.info('processing...');

                bowl(data).then(response => {
                    this.toast.clear()

                    this.undoCount = 0;
                    this.lastOver = response.data.last_over;
                    this.lastBowl = response.data.last_bowl;
                    this.recentRuns = response.data.recent_runs;


                    this.showWicket = false;
                    this.showFielder = false;

                    this.loadStrike(this.storedInning.id, this.selectedBowler.id);
                    this.initScoring();
                    this.outPlayerId = 0;

                }, error => {
                    this.outPlayerId = 0;
                    this.toast.clear();
                    this.toast.error(error.response.data.message);
                });
            }

        },

        undo() {


            if (this.undoCount > 3) {
                this.toast.warning('undo limited!')
            } else {
                this.$swal({

                    title: 'Are you sure?',

                    text: "You won't be able to revert this!",

                    type: 'warning',

                    showCancelButton: true,

                    confirmButtonColor: '#d33',

                    cancelButtonColor: '#3085d6',

                    confirmButtonText: 'Yes, delete it!'

                }).then((result) => {

                    if (result.value) {
                        this.toast.info('processing...')
                        this.loadScore = true;
                        undo(this.storedInning.id).then(response => {
                            this.undoCount++;
                            this.toast.clear();
                            this.lastBowl = response.data.last_bowl;

                            this.lastOver = response.data.last_over;
                            this.recentRuns = response.data.recent_runs;

                            this.totalInningRuns = response.data.totalInningRuns;
                            this.totalInningWickets = response.data.totalInningWickets;

                            this.loadScore = false;

                        }, error => {
                            this.toast.error(error.response.data.message);
                            this.loadScore = false;
                        });

                    }

                });
            }


        },

        getBatsmanPerformance(inning_id, player_id) {

            getBatsmanPerformance(inning_id, player_id).then(response => {
                return response.data;
            });


        },


        ...mapMutations({
            vuexSetLastOver: 'Score/setLastOver',
            vuexSetLastBowl: 'Score/setLastBowl',
            vuexSetBatsmenPerformance: 'Score/setBatsmenPerformance',
            vuexSetBatsmenLegalRunsScored: 'Score/setBatsmenLegalRunsScored',
            vuexSetBatsmenFacedLegalBalls: 'Score/setBatsmenFacedLegalBalls',
            vuexSetBowlerPerformance: 'Score/setBowlerPerformance',
            vuexSetBowlerGivenRuns: 'Score/setBowlerGivenRuns',
            vuexSetBowlerLegalRunsGiven: 'Score/setBowlerLegalRunsGiven',
            vuexSetBowlerLegalBallsBowled: 'Score/setBowlerLegalBallsBowled',
            vuexSetLegalBallsBowled: 'Score/setLegalBallsBowled',
            vuexSetInning: 'StartInning/setInning',
            vuexSetTotalInningRuns: 'Score/setTotalInningRuns',
            vuexSetTotalInningWickets: 'Score/setTotalInningWickets',
            vuexSetRecentRuns: 'Score/setRecentRuns',
            vuexSetLastScoreId: 'Score/setLastScoreId',

        }),
        ...mapGetters({
            vuexGetLastOver: 'Score/getLastOver',
            vuexGetLastBowl: 'Score/getLastBowl',
            vuexGetBatsmenPerformance: 'Score/getBatsmenPerformance',
            vuexGetBatsmenLegalRunsScored: 'Score/getBatsmenLegalRunsScored',
            vuexGetBatsmenFacedLegalBalls: 'Score/getBatsmenFacedLegalBalls',
            vuexGetBowlerPerformance: 'Score/getBowlerPerformance',
            vuexGetBowlerGivenRuns: 'Score/getBowlerGivenRuns',
            vuexGetBowlerLegalRunsGiven: 'Score/getBowlerLegalRunsGiven',
            vuexGetBowlerLegalBallsBowled: 'Score/getBowlerLegalBallsBowled',
            vuexGetLegalBallsBowled: 'Score/getLegalBallsBowled',
            vuexGetInning: 'StartInning/getInning',
            vuexGetTotalInningRuns: 'Score/getTotalInningRuns',
            vuexGetTotalInningWickets: 'Score/getTotalInningWickets',
            vuexGetRecentRuns: 'Score/getRecentRuns',
            vuexGetLastScoreId: 'Score/getLastScoreId',
        }),

        bowlerLegalBallsBowledF(bowler_id) {
            for (let i = 0; i < this.bowlerLegalBallsBowled.length; i++) {
                if (this.bowlerLegalBallsBowled[i].bowler_id === bowler_id) {

                    return this.bowlerLegalBallsBowled[i].bowl;


                }
            }
        }


    },
    computed: {

        selectedBowler() {
            for (let i = 0; i < this.bowler.length; i++) {
                if (this.bowler[i].status === 1) {
                    return this.bowler[i];
                }
            }

            return {
                id: 0,
            }
        },
        nonSelectedBowler() {
            for (let i = 0; i < this.bowler.length; i++) {
                if (this.bowler[i].status === 0) {
                    return this.bowler[i];
                }
            }
            return {
                id: 0,
            }
        },

        lastOver: {
            get() {
                return this.vuexGetLastOver();
            },
            set(value) {
                this.vuexSetLastOver(value);
            }

        },
        lastBowl: {
            get() {
                return this.vuexGetLastBowl();
            },
            set(value) {
                this.vuexSetLastBowl(value);
            }
        },

        batsmenPerformance: {
            get() {
                return this.vuexGetBatsmenPerformance();
            },
            set(value) {
                this.vuexSetBatsmenPerformance(value);
            }
        },

        batsmenLegalRunsScored: {
            get() {
                return this.vuexGetBatsmenLegalRunsScored();
            },
            set(value) {
                this.vuexSetBatsmenLegalRunsScored(value);
            }
        },

        batsmenFacedLegalBalls: {
            get() {
                return this.vuexGetBatsmenFacedLegalBalls();
            },
            set(value) {
                this.vuexSetBatsmenFacedLegalBalls(value);
            }
        },

        bowlerPerformance: {
            get() {
                return this.vuexGetBowlerPerformance();
            },
            set(value) {
                this.vuexSetBowlerPerformance(value);
            }
        },

        bowlerGivenRuns: {
            get() {
                return this.vuexGetBowlerGivenRuns();
            },
            set(value) {
                this.vuexSetBowlerGivenRuns(value);
            }
        },
        bowlerLegalRunsGiven: {
            get() {
                return this.vuexGetBowlerLegalRunsGiven();
            },
            set(value) {
                this.vuexSetBowlerLegalRunsGiven(value);
            }
        },

        bowlerLegalBallsBowled: {
            get() {
                return this.vuexGetBowlerLegalBallsBowled();
            },
            set(value) {
                this.vuexSetBowlerLegalBallsBowled(value);
            }
        },
        legalBallsBowled: {
            get() {
                return this.vuexGetLegalBallsBowled();
            },
            set(value) {
                this.vuexSetLegalBallsBowled(value);
            }
        },

        storedInning: {
            get() {
                return this.vuexGetInning();
            },
            set(value) {
                this.vuexSetInning(value);
            }
        },

        totalInningRuns: {
            get() {
                return this.vuexGetTotalInningRuns();
            },
            set(value) {
                this.vuexSetTotalInningRuns(value);
            }
        },

        totalInningWickets: {
            get() {
                return this.vuexGetTotalInningWickets();
            },
            set(value) {
                this.vuexSetTotalInningWickets(value);
            }
        },
        recentRuns: {
            get() {
                return this.vuexGetRecentRuns();
            },
            set(value) {
                return this.vuexSetRecentRuns(value);
            }
        },

        recentRuns2: ()=>{


        },
        lastScoreId: {
            get() {
                return this.vuexGetLastScoreId();
            },
            set(value) {
                return this.vuexSetLastScoreId(value);
            }
        },


    },
}