<template>
  <div class="row">
    <div class="col-12 col-md-6">
      <app-loader v-if="loadingFixtures"/>

      <div class="card h-300" v-else>

        <div class="text-title mb-2 mt-2 ml-3">
          <h5>
            Fixtures
          </h5>
        </div>
        <ul class="list-unstyled list-unstyled-border mb-0">
          <li class="media" v-for="(fixture, index) in fixtures" :key="index"
              @click="setFixture(fixture)"
              :class="{'bg-secondary': fixture.fixture_id === vuexGetFixture().fixture_id}">

            <div class="media-body ml-3 mt-1">
              <div class="media-title">
                {{ fixture.tournament }}
              </div>
              <div class="text-small text-muted">
                {{ fixture.fixture_title }} |
                {{ fixture.home_team_name }} vs {{ fixture.away_team_name }}
              </div>
            </div>
          </li>
        </ul>


      </div>
    </div>
    <div class="col-12 col-md-6">

      <div class="card h-300">


        <div class="text-title mb-2 mt-2 ml-3">
          <h5>
            Teams
          </h5>
        </div>
        <app-loader v-if="loadingTeam"/>
        <ul class="list-unstyled list-unstyled-border mb-0" v-else>
          <li class="media" v-for="(team, index) in vuexGetFixtureTeams()" :key="index"
              @click="setFixtureTeam(team)"
              :class="{'bg-secondary': team.id === vuexGetFixtureTeam().id}">
            <img alt="image" :src="$store.state.Global.rootCDN + team.logo"
                 class="user-img-radious-style user-list-img ml-3 mt-1"
                 width="40">
            <div class="media-body ml-3 mt-1">


              <div class="media-title">

                {{ team.name }}


              </div>

            </div>
          </li>
        </ul>


        <Toss/>


      </div>
    </div>
    <div class="col-12 col-md-6" v-if="vuexGetFixtureTeam().id">
      <div class="card">
        <div class="card-header">
          <h4>{{ vuexGetFixtureTeam().name }} Squad</h4>
        </div>
        <div class="card-body">
          <app-loader v-if="players.length < 1"/>
          <ul class="row list-unstyled list-unstyled-border mb-0" v-else>


            <li class="media col-12 col-md-6" v-for="(element, index) in players" :key="index">
              <img alt="image" :src="$store.state.Global.rootCDN + element.photo"
                   class="user-img-radious-style user-list-img"
                   width="40">
              <div class="media-body ml-2">
                <div class="media-title">{{ element.en_first_name }}
                  <button class="btn btn-primary btn-sm float-right"
                          @click.prevent="addSelectedPlayers(element,index)">
                    <i class="fas fa-angle-double-right"></i>
                  </button>
                </div>
                <div class="text-small text-muted">
                  <div class="avatar-badge">
                         <span class="badge badge-light" style="padding: 4px 5px;">
                            <i class="fas fa-list"></i> <span class="text-bold"> {{ index + 1 }} </span>
                            <i class="fas fa-arrow-up col-green ml-2"></i>
                            <i class="fas fa-arrow-down col-orange"></i>
                          </span>

                  </div>

                </div>
              </div>
            </li>


          </ul>

        </div>
      </div>


    </div>
    <div class="col-12 col-md-6" v-if="vuexGetFixtureTeam().id">
      <div class="card">
        <div class="card-header">
          <h4>Playing XI</h4>
        </div>
        <div class="card-body">

          <app-loader v-if="selectedPlayers.length < 1"/>
          <div v-else>
            <ul class="row list-unstyled list-unstyled-border mb-0">


              <draggable
                  v-if="selectedPlayers.length > 0"
                  v-model="selectedPlayers"
                  group="players"
                  @start="drag=true"
                  @end="drag=false"
                  tag="transition-group"
                  item-key="id">

                <template #item="{element, index}">
                  <li class="media col-12 col-md-6" :key="index">
                    <button class="btn btn-danger btn-sm float-right mr-1"
                            @click.prevent="removeSelectedPlayers(element,index)">
                      <i class="fas fa-angle-double-left"></i>
                    </button>
                    <img alt="image" :src="$store.state.Global.rootCDN + element.photo"
                         class="user-img-radious-style user-list-img"
                         width="40">
                    <div class="media-body ml-2">
                      <div class="media-title">
                        {{ element.en_first_name }}

                      </div>
                      <div class="text-small text-muted">
                        <div class="avatar-badge">
                          <span class="badge badge-light" style="padding: 4px 5px;">
                            <i class="fas fa-list"></i> <span class="text-bold"> {{ index + 1 }} </span>
                            <i class="fas fa-arrow-up col-green ml-2"></i>
                            <i class="fas fa-arrow-down col-orange"></i>
                          </span>


                        </div>

                      </div>
                    </div>
                  </li>
                </template>
              </draggable>


            </ul>
            <button class="btn btn-primary btn-sm btn-block mt-1" @click.prevent="storePlayingXI"
                    style="padding: .10rem .4rem;">
              Save
            </button>
          </div>


        </div>
      </div>


    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <MatchResult :defaultStatus="2" v-if="!loadingTeam"/>
    </div>
  </div>

</template>
<script>

import Toss from "@/components/scoringAndPerformance/launchMatch/components/Toss";
import {
  destroyPlayingXI,
  getFixture,
  getFixtureTeamPlayers,
  getFixtureTeams,
  storePlayerXI
} from "@/services/launchMatch/Match";
import {mapGetters, mapMutations} from 'vuex';
import draggable from 'vuedraggable'
import Loader from "@/components/layouts/parts/Loader";
import {useToast} from "vue-toastification";
import MatchResult from "@/components/scoringAndPerformance/launchMatch/components/MatchResult";

export default {
  data() {
    return {
      loadingFixtures: true,
      toast: useToast(),
      loadingTeam: false,
    }
  },
  components: {
    draggable,
    appLoader: Loader,
    Toss,
    MatchResult,
  },

  computed: {
    fixtures: {
      get() {
        return this.vuexGetFixtures();
      },
      set(value) {
        this.vuexSetFixtures(value);
      }
    },
    players: {
      get() {
        return this.vuexGetTeamPlayers();
      },
      set(value) {
        this.vuexSetTeamPlayers(value)
      }
    },
    selectedPlayers: {
      get() {
        return this.vuexGetTeamSelectedPlayers();
      },
      set(value) {
        this.vuexSetTeamAllSelectedPlayers(value);
      }
    }
  },

  methods: {
    ...mapMutations({
      vuexSetFixtures: 'LaunchMatch/setFixtures',
      vuexSetFixture: 'LaunchMatch/setFixture',
      vuexSetFixtureTeams: 'LaunchMatch/setFixtureTeams',
      vuexSetFixtureTeam: 'LaunchMatch/setFixtureTeam',
      vuexSetTeamPlayers: 'LaunchMatch/setTeamPlayers',
      vuexSetTeamPlayer: 'LaunchMatch/setTeamPlayer',
      vuexSetTeamSelectedPlayers: 'LaunchMatch/setTeamSelectedPlayers',
      vuexSetTeamAllSelectedPlayers: 'LaunchMatch/setTeamAllSelectedPlayers',
      vuexRemoveTeamSelectedPlayer: 'LaunchMatch/removeTeamSelectedPlayer',
      vuexRemoveTeamPlayer: 'LaunchMatch/removeTeamPlayer',
      vuexSetElectedTo: 'LaunchMatch/setElectedTo',
      vuexSetYesTheyWon: 'LaunchMatch/setYesTheyWon',
    }),
    ...mapGetters({
      vuexGetFixtures: 'LaunchMatch/getFixtures',
      vuexGetFixture: 'LaunchMatch/getFixture',
      vuexGetFixtureTeams: 'LaunchMatch/getFixtureTeams',
      vuexGetFixtureTeam: 'LaunchMatch/getFixtureTeam',
      vuexGetTeamPlayers: 'LaunchMatch/getTeamPlayers',
      vuexGetTeamSelectedPlayers: 'LaunchMatch/getTeamSelectedPlayers',
      vuexGetElectedTo: 'LaunchMatch/getElectedTo',
    }),

    storePlayingXI() {
      let data = {
        players: [],
        fixture_id: this.vuexGetFixture().fixture_id,
        team_id: this.vuexGetFixtureTeam().id,
      };

      for (let i = 0; i < this.vuexGetTeamSelectedPlayers().length; i++) {

        data.players.push({
          player_id: this.vuexGetTeamSelectedPlayers()[i].id,
          playing_xi_id: this.vuexGetTeamSelectedPlayers()[i].playing_xi_id
        });

      }


      this.toast.info('Setting Playing XI...')

      storePlayerXI(data).then(response => {
        this.toast.clear();
        this.vuexSetTeamAllSelectedPlayers(response.data);
      }, error => {
        this.toast.clear();
        this.toast.error(error.response.data.message);
      });
    },

    addSelectedPlayers(player, index) {
      this.vuexSetTeamSelectedPlayers(player);
      this.vuexRemoveTeamPlayer(index);
    },
    removeSelectedPlayers(player, index) {


      let data = {
        playing_xi_id: player.playing_xi_id,
        fixture_id: this.vuexGetFixture().fixture_id,
        team_id: this.vuexGetFixtureTeam().id,
      };

      console.log(data);


      if (data.playing_xi_id > 0) {

        this.$swal({

          title: 'Are you sure?',

          text: "You won't be able to revert this!",

          type: 'warning',

          showCancelButton: true,

          confirmButtonColor: '#d33',

          cancelButtonColor: '#3085d6',

          confirmButtonText: 'Yes, delete it!'

        }).then((result) => {

          if (result.value) {

            this.toast.info('Deleting record!');

            destroyPlayingXI(data).then(() => {
              this.toast.clear()
              this.vuexRemoveTeamSelectedPlayer(index);
              this.vuexSetTeamPlayer(player);
            }, error => {
              this.toast.clear();
              this.toast.error(error.response.data.message)
            });


          }

        });
      } else {
        this.vuexRemoveTeamSelectedPlayer(index);
        this.vuexSetTeamPlayer(player);
      }


    },

    getFixture() {


      this.vuexSetYesTheyWon(false);
      this.vuexSetFixtureTeams([]);
      this.vuexSetFixtureTeam({});

      this.loadingFixtures = true;


      getFixture().then(response => {
        this.loadingFixtures = false;
        this.vuexSetFixtures(response.data);


        if (this.vuexGetFixtures().length <= 0) {
          this.vuexSetFixture({});
          this.vuexSetFixtureTeams([]);
        }
      });

    },

    setFixture(fixture) {



      this.vuexSetFixture(fixture);

      let data = {
        fixture_id: fixture.fixture_id
      };

      this.vuexSetFixtureTeams([]);
      this.vuexSetFixtureTeam({});


      this.loadingTeam = true;


      getFixtureTeams(data).then(response => {
        this.loadingTeam = false;

        this.vuexSetFixtureTeams(response.data.teams);
        this.vuexSetElectedTo(response.data.toss ? response.data.toss : {});
      });

      MatchResult.methods.getResult(fixture.fixture_id);
    },

    setFixtureTeam(team) {


      this.vuexSetFixtureTeam(team);

      this.vuexSetTeamPlayers([]);
      this.vuexSetTeamAllSelectedPlayers([]);

      let electedTo = this.vuexGetElectedTo();

      if (electedTo.team_id == this.vuexGetFixtureTeam().id && electedTo.fixture_id === this.vuexGetFixture().fixture_id) {
        this.vuexSetYesTheyWon(true);
      } else {
        this.vuexSetYesTheyWon(false);
      }


      let data = {
        team_id: this.vuexGetFixtureTeam().id,
        fixture_id: this.vuexGetFixture().fixture_id,
        tournament_id: this.vuexGetFixture().tournament_id,
        home_team_id: this.vuexGetFixture().home_team_id,
        away_team_id: this.vuexGetFixture().away_team_id,
      }

      getFixtureTeamPlayers(data).then(response => {

        this.vuexSetTeamPlayers(response.data.players);
        this.vuexSetTeamAllSelectedPlayers(response.data.playing_xi);

      });

    }

  },

  mounted() {
    this.getFixture();
  }
}
</script>
<style scoped>
.bg-secondary {
  background-color: #045BA6 !important;
  border-bottom: 3px solid #023C6E;

}

.bg-secondary .media-body .media-title {
  color: #ffffff;
}

.bg-secondary .media-body .text-small {
  color: lighgray;;
}

.btn.btn-sm {
  padding: .8rem .2rem;

}

.media:hover, .media:active {
  cursor: move;
}

.media-title {
  font-size: 12px;
}

@media only screen and (min-width: 700px) {
  .h-300 {
    max-height: 300px !important;
    overflow-y: auto;
  }
}

</style>