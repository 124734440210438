<template>
  <div class="row box">
    <div class="col-12">
      <h6 style="background-color: rgb(172, 200, 223); color: #045BA6; border-radius: 0 0 5px 5px;" class="p-1">
        Match Notes
      </h6>
    </div>

    <div class="col-12 col-md-12 form-group">
      <label>Note</label>
      <textarea name="" v-model="note.note" placeholder="Match Note" class="form-control" cols="30" rows="1"></textarea>
    </div>


    <div class="col-4">
      <button class="btn btn-primary" @click="store" type="button">Submit</button>


    </div>
    <div class="col-4">
      <Loader style="height: 30px;" v-if="loading"/>
    </div>

    <div class="col-12 mt-3">
      <label for="">All Notes</label>
      <table class="table table-bordered">
        <tr v-for="(note, index) in notes" :key="index">
          <td class="text-muted">

               {{ note.note }}

            <button class="btn btn-danger float-right m-1"
                    style="padding: -0.1rem !important; font-size: 10px !important;" @click="showNote(note.id)">
              <span v-if="note.show === 0" title="show">

                <i class="fa fa-eye"></i>

              </span>
              <span v-else title="hide">

                <i class="fa fa-eye-slash"></i>
              </span>
            </button>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>
<script>
import {StartInning} from "@/mixins/launchMatch/StartInning";
import Loader from "@/components/layouts/parts/Loader";
import {get, showNote, store} from "@/services/launchMatch/MatchNote";

export default {
  mixins: [StartInning],
  data() {
    return {
      loading: false,
      note: {note: ''},
      notes: [],
    }
  },
  components: {
    Loader,
  },

  methods: {

    store() {
      this.loading = true;
      this.note.fixture_id = this.fixture.fixture_id;

      store(this.note).then(response => {

        this.notes = response.data;
        this.note.note = "";
        this.loading = false;


      }, () => {
        this.loading = false;
      });
    },

    get() {

      get(this.fixture.fixture_id).then(response => {
        this.notes = response.data;

        this.loading = false;
      });

    },
    showNote(note_id) {
      this.loading = true;
      showNote(note_id).then(() => {
        this.get();
      });
    }

  },

  mounted() {
    this.get();
  }
}
</script>
<style>

</style>