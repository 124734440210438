import API from '../API';

export function store(data) {
    return API.post('match-note/store', data);
}

export function get(fixture_id) {
    return API.get('match-note/get?fixture_id=' + fixture_id);
}

export function showNote(note_id) {
    return API.get('match-note/show-note?note_id=' + note_id);
}