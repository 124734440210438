import API from '../API';

export function getFixture() {
    return API.get('launch-match/fixture/show');
}

export function getFixtureTeams(data) {
    return API.post('launch-match/fixture/getTeams', data);
}

export function getFixtureTeamPlayers(data) {
    return API.post('launch-match/fixture/team-players', data);
}

export function storePlayerXI(data) {
    return API.post('launch-match/playing-xi/store', data);
}

export function destroyPlayingXI(data) {
    return API.post('launch-match/playing-xi/destroy', data);
}

export function storeToss(data) {
    return API.post('launch-match/toss/store', data);
}

