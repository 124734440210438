export const Performance = {
    data() {
        return {
            over: 0,
            overRuns: 0,
        };
    },
    methods: {
        batsmanRuns(player_id) {
            let performance = this.batsmenPerformance;
            let runs = 0;
            for (let i = 0; i < performance.length; i++) {
                if (performance[i].striker_id === player_id) {
                    runs += performance[i].runs;
                }
            }
            return runs;
        },

        batsmenBall(player_id) {
            let performance = this.batsmenPerformance;
            let ball = 0;
            // let over = 0;

            for (let i = 0; i < performance.length; i++) {

                if (performance[i].striker_id === player_id)

                    // over !== performance[i].over &&
                    if (ball !== performance[i].bowl && performance[i].bowl !== 0) {
                        ball += 1;
                        // over = performance[i].over;
                    }


            }

            return ball;
        },
        batsmen4s(player_id) {
            let performance = this.batsmenPerformance;
            let fours = 0;
            for (let i = 0; i < performance.length; i++) {
                if (performance[i].striker_id === player_id) {
                    if (performance[i].runs_type === 2 && performance[i].runs === 4) {
                        fours += 1;
                    }

                }
            }
            return fours;
        },
        batsmen6s(player_id) {
            let performance = this.batsmenPerformance;
            let sixes = 0;
            for (let i = 0; i < performance.length; i++) {
                if (performance[i].striker_id === player_id) {
                    if (performance[i].runs_type === 2 && performance[i].runs === 6) {
                        sixes += 1;
                    }
                }
            }
            return sixes;
        },
        validBallForBowler(performance) {
            return (performance.extra_runs_type_id === 3
                || performance.extra_runs_type_id === 4
                || performance.extra_runs_type_id === 5
                || performance.extra_runs_type_id === 6
                || performance.extra_runs_type_id === 7
                || performance.runs_type === 1
                || performance.runs_type === 2
                || performance.runs_type === 3)
        },

        bowlerOvers(player_id, bowlerPerformance) {
            let performance = bowlerPerformance;
            let bowl = 0;
            for (let i = 0; i < performance.length; i++) {

                if (performance[i].bowler_id === player_id) {

                    // if (performance[i].over !== overs) {

                    if (this.validBallForBowler(performance[i])) {
                        bowl += 1;
                    }

                    // }
                }

            }
            return (bowl / 6).toFixed(0);
        },

        bowlerWickets(player_id, bowlerPerformance) {
            let performance = bowlerPerformance;

            let wicket = 0;

            for (let i = 0; i < performance.length; i++) {
                if (performance[i].bowler_id === player_id) {

                    if (
                        performance[i].wicket_type_id === 1
                        || performance[i].wicket_type_id === 2
                        || performance[i].wicket_type_id === 3
                        || performance[i].wicket_type_id === 5
                        || performance[i].wicket_type_id === 6
                    ) {

                        wicket += 1;
                    }


                }
            }

            return wicket;
        },

        batsmanRunsScored(player_id, batsmenLegalRunsScored) {
            let batsmenRuns = batsmenLegalRunsScored;
            let runs = 0;

            for (let i = 0; i < batsmenRuns.length; i++) {

                if (batsmenRuns[i].striker_id === player_id) {
                    runs += batsmenRuns[i].runs;
                }

            }

            return runs;
        },
        batsmanBoundaries(player_id, runs, batsmenLegalRunsScored) {
            let batsmenRuns = batsmenLegalRunsScored;
            let boundaries = 0;

            for (let i = 0; i < batsmenRuns.length; i++) {

                if (batsmenRuns[i].striker_id === player_id) {
                    if (batsmenRuns[i].runs_type === 2 && batsmenRuns[i].runs === runs) {
                        boundaries += 1;
                    }

                }

            }

            return boundaries;
        },

        batsmanBallsFaced(player_id, batsmenFacedLegalBalls) {
            let batsmenBalls = batsmenFacedLegalBalls;
            let balls = 0;


            for (let i = 0; i < batsmenBalls.length; i++) {

                // && (batsmenBalls[i].bowl !== 0 && batsmenBalls[i].extra_runs_type_id !== 3)
                if (batsmenBalls[i].striker_id === player_id) {
                    balls += 1;
                }

            }

            return balls;
        },


        bowlerRuns(player_id, bowlerGivenRuns) {
            let runsGiven = bowlerGivenRuns;
            let runs = 0;
            for (let i = 0; i < runsGiven.length; i++) {
                if (runsGiven[i].bowler_id === player_id) {
                    runs += (runsGiven[i].runs + runsGiven[i].extra_runs);
                }
            }
            return runs;
        },

        bowlerMaidenOvers(player_id, bowlerLegalRunsGiven) {
            let runsGiven = bowlerLegalRunsGiven;
            let overs = 0;


            for (let i = 0; i < runsGiven.length; i++) {

                if (runsGiven[i].bowler_id === player_id) {


                    if (runsGiven[i].runs == 0 && runsGiven[i].extra_runs_type_id != 3 && runsGiven[i].extra_runs_type_id != 4) {
                        overs++;
                    }

                }
            }

            return overs;
        },


    }
}