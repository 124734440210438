<template>
  <div class="row box">
    <div class="col-12">

      <h6 style="background-color: rgb(172, 200, 223); color: #045BA6; border-radius: 0 0 5px 5px;" class="p-1" @click="loadInnings">
        Full Score Card
        <i class="fa  float-right"
           :class="{'fa-angle-down': !showResult, 'fa-angle-up': showResult}"></i>
      </h6>


      <div class="d-inline-block result-box" v-for="(inning, index) in completedInnings" :key="index">

        <p v-if="showResult">
          {{ inning.team_name }}
          {{ getInningResult(inning.id).total_score }} / {{ getInningResult(inning.id).wickets }} |
        </p>

      </div>


    </div>


    <transition enter-active-class="animate__animated animate__fadeIn">

      <div class="col-12" v-if="showResult">
        <div id="accordion">
          <div class="accordion" v-for="(inning, index) in fixtureInnings" :key="index">
            <div class="accordion-header collapsed" role="button" data-toggle="collapse"
                 :data-target="'#panel-body-'+index"
                 aria-expanded="false"
                 @click="loadFullScoreCard(inning.id)"
            >
              <h4>{{ inning.name }}</h4>
            </div>
            <div class="accordion-body collapse pr-0 pl-0" :id="'panel-body-'+index" data-parent="#accordion"
                 style="">
              <app-loader v-if="loading"/>
              <div v-else>
                <table class="table table-bordered batsman">
                  <thead class="thead-light bg-light">
                  <tr>
                    <th style="width:25%">BATSMEN</th>
                    <th style="width:25%">&nbsp;</th>
                    <th style="width:8%">R</th>
                    <th style="width:8%">B</th>
                    <th style="width:8%">4s</th>
                    <th style="width:8%">6s</th>
                    <th style="width:10%">SR</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(batsman, index) in fsc_batsmen" :key="index">
                    <td>
                      <a href="#" data-hover="" class="small" target="_parent" rel="" title="View full profile ***">
                        {{ batsman.en_first_name }} {{ batsman.en_last_name }}
                      </a>
                    </td>
                    <td class="text-left">Out Type</td>
                    <td class="font-weight-bold">{{ batsmanRunsScored(batsman.id, legalRunsScored) }}</td>
                    <td>
                      {{ batsmanBallsFaced(batsman.id, legalRunsScored) }}
                    </td>
                    <td>
                      {{ batsmanBoundaries(batsman.id, 4, legalRunsScored) }}
                    </td>
                    <td>{{ batsmanBoundaries(batsman.id, 6, legalRunsScored) }}</td>
                    <td v-if="batsmanRunsScored(batsman.id, legalRunsScored) !== 0 && batsmanBallsFaced(batsman.id, facedLegalBalls) !== 0">

                      {{
                        ((batsmanRunsScored(batsman.id, legalRunsScored) * 100) / batsmanBallsFaced(batsman.id, facedLegalBalls)).toFixed(2)
                      }}
                    </td>

                    <td v-else>
                      0
                    </td>
                  </tr>


                  <tr class="extras">
                    <td colspan="1">Extras</td>
                    <td class="text-left">
                      (<span v-for="(extraRunsType, index) in extraRunsTypes" :key="index">
                          <span v-for="(extraRun, index) in extraRuns" :key="index">
                            <span v-if="extraRun.extra_runs_type_id === extraRunsType.id">
                              {{ extraRunsType.abbreviation }} {{ extraRun.sum }},
                            </span>

                          </span>
                        </span>
                      )

                    </td>
                    <td colspan="1" class="text-right font-weight-bold">{{ sumExtras }}</td>
                    <td colspan="7" class="text-right"></td>
                  </tr>
                  </tbody>
                  <tfoot>
                  <tr class="thead-light bg-light total">
                    <td colspan="1">TOTAL</td>
                    <td class="text-left">
                      (<span>
                         &nbsp;
                        </span>
                      <span v-if="legalBallsBowled > 5">

                          {{ Math.trunc(inningLegalBallsBowled / 6) + '.' + (inningLegalBallsBowled % 6) }} Ov

                        </span>
                      <span v-else>

                          0.{{ legalBallsBowled }} Ov

                        </span>
                      ,
                      RR: {{
                        (allTotalInningRuns / ((inningLegalBallsBowled / 6) + (inningLegalBallsBowled % 6))).toFixed(2)
                      }})


                    </td>
                    <td colspan="1" class="text-right font-weight-bold">{{ allTotalInningRuns }} / {{
                        allTotalInningWickets
                      }} &nbsp;
                    </td>
                    <td colspan="7" class="text-right"></td>
                  </tr>
                  <tr>
                    <td colspan="9">
                      <div>

                      </div>
                    </td>
                  </tr>
                  </tfoot>
                </table>

                <table class="table table-bordered bowler">
                  <thead class="thead-light bg-light">
                  <tr>
                    <th style="width: 20%;">BOWLING</th>
                    <th style="width: 10%;">O</th>
                    <th style="width: 10%;">M</th>
                    <th style="width: 10%;">R</th>
                    <th style="width: 10%;">W</th>
                    <th style="width: 10%;">ECON</th>
                    <th style="display: none;">0s</th>
                    <th style="display: none;">4s</th>
                    <th style="display: none;">6s</th>
                    <th style="width: 10%;">WD</th>
                    <th style="width: 10%;">NB</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(bowler, index) in fsc_bowlers" :key="index">

                    <td class="text-nowrap" style="width: 20%;">
                      <a href="#"
                         data-hover="" class="small" target="_parent" rel=""
                         title="View full profile of ***">
                        {{ bowler.en_first_name + ' ' + bowler.en_last_name }}
                      </a>
                    </td>
                    <td>

                        <span v-for="(lbb, index) in allLegalBallsBowled" :key="index">
                          <span v-if="bowler.id === lbb.bowler_id">
                            {{ Math.trunc(lbb.legal_balls / 6) + '.' + (lbb.legal_balls % 6) }}
                          </span>

                        </span>

                    </td>

                    <td>
                      {{ bowlerMaidenOvers(bowler.id, allBowlerLegalRunsGiven) }}
                    </td>
                    <td>
                      {{ bowlerRuns(bowler.id, allBowlerGivenRuns) }}
                    </td>
                    <td>
                      {{ bowlerWickets(bowler.id, allBowlerPerformance) }}
                    </td>
                    <td v-if="bowlerRuns(bowler.id, allBowlerGivenRuns) !== 0 && bowlerOvers(bowler.id, allBowlerPerformance) !== 0">


                        <span v-for="(lbb, index) in allLegalBallsBowled" :key="index">
                          <span v-if="bowler.id === lbb.bowler_id">

                             {{ (bowlerRuns(bowler.id, allBowlerGivenRuns) / (lbb.legal_balls / 6)).toFixed(2) }}
                          </span>

                        </span>

                    </td>
                    <td v-else>0</td>
                    <td>
                          <span v-for="(wd, index) in wds" :key="index">
                            <span v-if="wd.bowler_id === bowler.id">
                              <span v-if="wd.wds">
                                {{ wd.wds }}
                              </span>
                            </span>
                            <span v-else>
                              0
                            </span>

                          </span>
                      <span v-if="wds.length === 0">0</span>
                    </td>
                    <td>

                          <span v-for="(nb, index) in nbs" :key="index">
                            <span v-if="nb.bowler_id === bowler.id">

                              <span v-if="nb.nbs">
                                {{ nb.nbs }}
                              </span>

                            </span>

                            <span v-else>0</span>

                          </span>
                      <span v-if="nbs.length === 0">0</span>

                    </td>
                  </tr>

                  </tbody>
                </table>
              </div>

            </div>
          </div>

        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import {FullScoreCardMixin} from "@/mixins/launchMatch/FullScoreCard";
import {Scoring} from "@/mixins/launchMatch/Scoring";
import {Performance} from "@/mixins/launchMatch/Performance";
import {StartInning} from "@/mixins/launchMatch/StartInning";
import api from "@/services/API";
import Loader from "@/components/layouts/parts/Loader";
import {searchPlayerInObject} from '@/services/LocalSearch'

export default {
  mixins: [FullScoreCardMixin, Scoring, Performance, StartInning],
  data() {

    return {
      showResult: false,
      showFSC: false,
      loading: false,
      fixtureInnings: [],
      fsc_batsmen: [],
      fsc_bowlers: [],
      fsc_score: [],
      legalRunsScored: [],
      facedLegalBalls: [],
      extraRunsTypes: [],
      sumExtras: '',
      extraRuns: [],
      allLegalBallsBowled: [],
      allBowlerGivenRuns: [],
      allBowlerPerformance: [],
      wds: [],
      nbs: [],
      allTotalInningRuns: '',
      allTotalInningWickets: '',
      inningLegalBallsBowled: '',
      allBowlerLegalRunsGiven: [],


    };

  },

  components: {
    appLoader: Loader,
  },
  methods: {

    searchPlayerInObject(data, seek) {
      searchPlayerInObject(data, seek);
    },

    loadFullScoreCard(inning_id) {


      this.loading = true;

      api.get('full-score-card?fixture_id=' + this.storedInning.fixture_id + '&inning_id=' + inning_id).then(response => {
        this.loading = false;

        this.fsc_batsmen = response.data.batsmen;
        this.fsc_bowlers = response.data.bowlers;
        this.fsc_score = response.data.score;
        this.legalRunsScored = response.data.batsmenLegalRunsScored;
        this.facedLegalBalls = response.data.batsmenFacedLegalBalls;
        this.extraRunsTypes = response.data.extraRunsTypes;
        this.sumExtras = response.data.sumExtras;
        this.extraRuns = response.data.extraRuns;
        this.allLegalBallsBowled = response.data.bowlerLegalBallsBowled;
        this.allBowlerGivenRuns = response.data.bowlerGivenRuns;
        this.allBowlerPerformance = response.data.bowlerPerformance
        this.wds = response.data.wds;
        this.nbs = response.data.nbs;
        this.allTotalInningRuns = response.data.allTotalInningRuns;
        this.allTotalInningWickets = response.data.allTotalInningWickets;
        this.inningLegalBallsBowled = response.data.inningLegalBallsBowled;
        this.allBowlerLegalRunsGiven = response.data.allBowlerLegalRunsGiven;


      }, () => {

        this.loading = false;
      });

    },

    loadInnings() {
      this.showResult = !this.showResult

      if (this.showResult) {
        api.get('fixtures/innings?fixture_id=' + this.storedInning.fixture_id).then(response => {
          this.fixtureInnings = response.data;
        })
      }
    }

  },
}
</script>
<style >

</style>