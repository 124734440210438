import API from '../API';

export function initScoring(inning_id, fixture_id) {
    return API.get('launch-match/scoring/init-scoring?inning_id=' + inning_id + '&fixture_id=' + fixture_id + "&admin=" + true);
}

export function bowl(data) {
    return API.post('launch-match/scoring/store', data);
}

export function undo(inning_id) {
    return API.get('launch-match/scoring/undo?inning_id=' + inning_id);
}

export function getBatsmanPerformance(inning_id, player_id) {
    return API.get('launch-match/scoring/get-batsman-performance?inning_id=' + inning_id + '&player_id=' + player_id);
}