<template>
  <div class="row" data-v-4e6a218e="">
    <div class="col-12">
      <ul class="list-unstyled list-unstyled-border mb-0">

        <li class="media bg-secondary">
          <div class="media-body ml-3 mt-1 mr-3">
            <div class="media-title">
              {{ fixture.tournament }}

            </div>
            <div class="text-small text-muted">
              {{ fixture.fixture_title }} |
              {{ fixture.home_team_name }} vs {{ fixture.away_team_name }}
            </div>
          </div>
        </li>


      </ul>

    </div>
  </div>

  <div class="row">
    <div class="col-12 col-md-8 ">
      <div class="card ">
        <div class="card-body">

          <app-scoring/>

        </div>
      </div>
    </div>
    <div class="col-12 col-md-4 ">
      <div class="card ">
        <!--        @click="showBowlers = !showBowlers"-->
        <div class="card-header">
          <h4>Bowlers</h4>
          <i class="fa fa-angle-down pull-right" v-if="!showBowlers"></i>
          <i class="fa fa-angle-up pull-right" v-if="showBowlers"></i>
        </div>
        <div class="card-body h2-340">
          <!--            v-if="showBowlers"-->
          <div class="row">
            <div class="col-12">
              <ul class="list-unstyled list-unstyled-border user-list" id="message-list">
                <!--                  @click="selectBowler(element)"-->

                <li class="media" v-for="(element,index) in cBowlers"

                    :key="index">

                  <img alt="image" :src="$store.state.Global.rootCDN + element.photo"
                       class="mr-3 user-img-radious-style user-list-img">
                  <div class="media-body">

                    <div class="mt-0 font-weight-bold">

                      <small>{{ element.id }} - </small>
                      <span v-if="element.en_last_name.length > 1">
                                          {{ element.en_first_name.split(' ')[0].charAt(0) }}<span
                          v-if="element.en_first_name.split(' ')[1]">{{
                          element.en_first_name.split(' ')[1].charAt(0)
                        }}</span>
                          </span>
                      <span v-else>
                            {{ element.en_first_name }}
                          </span>
                      <span>
                            {{ " " + element.en_last_name }}
                          </span>

                      <button class="btn btn-primary btn-sm float-right" v-if="storedInning.id"
                              @click.prevent="addToBowlers(element, index)">
                        <i class="fas fa-angle-double-right"></i>
                      </button>
                    </div>

                    <div class="text-small">
                      <span v-if="element.bowling_type !== 'SLO'">{{ element.batting_style }}</span>
                      <span>{{ element.bowling_type }}</span>
                    </div>
                  </div>

                </li>


              </ul>
            </div>
          </div>


        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-12 col-md-2 text-center">
              <fieldset class="mb-2">
                <legend style="color: red; width: 70%;">Live</legend>
                <div class="form-group mb-0">

                  <label class="custom-switch mb-2" style="display: inline-block;">
                    <input type="radio" name="match_status" value="1" class="custom-switch-input "
                           @change="updateMatchStatus({dtype: 1, status: 1})"
                           :checked="selectedMatchStatus.dtype === 1 && selectedMatchStatus.status === 1">
                    <span class="custom-switch-indicator"></span>
                    <span class="custom-switch-description">Live</span>
                  </label>

                </div>
              </fieldset>
            </div>
            <div class="col-12 col-md-5 text-center">
              <fieldset class="mb-1">
                <legend>Delays</legend>
                <div class="form-group mb-0">

                  <label class="custom-switch mb-2" style="display: inline-block;" v-for="(delay, index) in delays"
                         :key="index">
                    <input type="radio" name="match_status" :value="delay.id" class="custom-switch-input"
                           @change="updateMatchStatus({dtype: 3, status: delay.id})"
                           :checked="selectedMatchStatus.dtype === 3 && selectedMatchStatus.status === delay.id">
                    <span class="custom-switch-indicator"></span>
                    <span class="custom-switch-description">{{ delay.name }}</span>
                  </label>


                </div>
              </fieldset>
            </div>
            <div class="col-12 col-md-5 text-center">
              <fieldset class="mb-1">
                <legend>Breaks</legend>
                <div class="form-group mb-0">

                  <label class="custom-switch mb-2" style="display: inline-block;" v-for="(breakk, index) in breaks"
                         :key="index">

                    <input type="radio" name="match_status" :value="breakk.id" class="custom-switch-input"
                           @change="updateMatchStatus({dtype: 4, status: breakk.id})"
                           :checked="selectedMatchStatus.dtype === 4 && selectedMatchStatus.status === breakk.id">

                    <span class="custom-switch-indicator"></span>
                    <span class="custom-switch-description">{{ breakk.name }}</span>
                  </label>


                </div>
              </fieldset>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12 col-md-6">
      <div class="card">
        <div class="text-title mb-2 mt-2 ml-3">
          <h5> Crease Batsmen </h5>
        </div>

        <div class="card-body  h-340">

          <div class="row">
            <div class="col-12 col-md-6">
              <p class="text text-small border-bottom-1">Playing XI</p>
              <ul class="row list-unstyled list-unstyled-border mb-0" style="height:260px; overflow: auto">

                <li class="media col-12" v-for="(batsman, index) in batsmen" :key="index">

                  <img alt="image"
                       class="user-img-radious-style user-list-img"
                       :src="$store.state.Global.rootCDN + batsman.photo"
                       width="40">

                  <div class="media-body ml-2">

                    <div class="media-title">
                      <small>{{ batsman.id }}</small> -

                      <span v-if="batsman.en_last_name.length > 1">
                                          {{ batsman.en_first_name.split(' ')[0].charAt(0) }}<span
                          v-if="batsman.en_first_name.split(' ')[1]">{{
                          batsman.en_first_name.split(' ')[1].charAt(0)
                        }}</span>
                                      </span>
                      <span v-else>
                        {{ batsman.en_first_name }}
                      </span>
                      <span>
                         {{ " " + batsman.en_last_name }}
                      </span>

                      <button class="btn btn-primary btn-sm float-right" v-if="storedInning.id" title="add to strike"
                              @click.prevent="addToStrike(batsman, index)">
                        <i class="fas fa-angle-double-right"></i>
                      </button>
                    </div>

                    <div class="text-small text-muted">
                      <div class="avatar-badge">
                        <span>{{ batsman.batting_style }}</span>
                      </div>
                    </div>

                  </div>

                </li>

              </ul>
            </div>
            <div class="col-12 col-md-6">
              <p class="text text-small">Strikers</p>
              <ul class="row list-unstyled list-unstyled-border mb-0">
                <draggable

                    v-model="strikePlayers"
                    group="strikers"
                    @start="drag=true"
                    @end="drag=false"
                    @change="changeStrike"
                    tag="transition-group"
                    item-key="id">

                  <template #item="{element, index}">
                    <li class="media col-12" :key="index" v-if="element.player_playing_status_id === 1">

                      <button class="btn btn-danger btn-sm float-left mr-2" v-if="storedInning.id"
                              title="remove form strike"
                              @click="removeFromCrease(element)">
                        <i class="fas fa-angle-double-left"></i>
                      </button>

                      <img alt="image"
                           class="user-img-radious-style user-list-img"
                           :src="$store.state.Global.rootCDN + element.photo"
                           width="40">

                      <div class="media-body ml-2">
                        <div class="media-title">
                          <span v-if="element.en_last_name.length > 1">
                                          {{ element.en_first_name.split(' ')[0].charAt(0) }}<span
                              v-if="element.en_first_name.split(' ')[1]">{{
                              element.en_first_name.split(' ')[1].charAt(0)
                            }}</span>
                          </span>
                          <span v-else>
                            {{ element.en_first_name }}
                          </span>
                          <span>
                            {{ " " + element.en_last_name }}
                          </span>
                          <span class="text text-small col-red" v-if="element.striker === 1">
                            *
                          </span>

                        </div>
                        <div class="text-small text-muted">
                          <div class="avatar-badge">

                            <a href="" class="badge badge-danger font-9  mr-1 p-1"
                               @click.prevent="changePlayingStatus(element.player_id, 4)">
                              DC
                            </a>
                            <a href="" class="badge badge-danger font-9 p-1"
                               @click.prevent="changePlayingStatus(element.player_id, 3)"
                            >
                              RH
                            </a>
                            batsman
                          </div>
                        </div>
                      </div>
                    </li>
                  </template>
                </draggable>


              </ul>

              <p class="text text-small">Outed Strikers</p>
              <ul class="row list-unstyled list-unstyled-border mb-0" style="height: 65px; overflow: auto;">
                <li class="media col-12" v-for="(element, index) in outPlayers" :key="index">

                  <button class="btn btn-danger btn-sm float-left mr-2" v-if="storedInning.id"
                          title="add to strike again"
                          @click="addToStrikeAgain(element)">
                    <i class="fas fa-angle-double-up"></i>
                  </button>

                  <img alt="image"
                       class="user-img-radious-style user-list-img"
                       :src="$store.state.Global.rootCDN + element.photo"
                       width="40">

                  <div class="media-body ml-2">
                    <div class="media-title">
                      <span v-if="element.en_last_name.length > 1">
                                          {{ element.en_first_name.split(' ')[0].charAt(0) }}<span
                          v-if="element.en_first_name.split(' ')[1]">{{
                          element.en_first_name.split(' ')[1].charAt(0)
                        }}</span>
                          </span>
                      <span v-else>
                            {{ element.en_first_name }}
                          </span>
                      <span>
                            {{ " " + element.en_last_name }}
                          </span>
                      <span class="text text-small col-red" v-if="element.striker === 1">
                            *
                          </span>

                    </div>
                  </div>

                </li>
              </ul>

            </div>


          </div>

        </div>


      </div>
    </div>
    <div class="col-12 col-md-6">
      <div class="card">
        <div class="text-title mb-2 mt-2 ml-3">
          <h5> Innings </h5>
        </div>

        <div class="card-body h-340">
          <div class="row" v-if="!storedInning.id">
            <div class="col-12 col-md-6 ">
              <div class="form-group">
                <label for="captain_id">Captain</label>
                <select id="captain_id" v-model="storedInning.captain_id" class="form-control form-control-sm"
                        name="captain_id">
                  <option v-for="(player, index) in bowlers" :key="index" :value="player.id">
                    {{ player.en_first_name }}
                    {{ player.en_last_name }}
                  </option>
                </select>
              </div>
            </div>

            <div class="col-12 col-md-6">
              <div class="form-group">
                <label for="wicket_keeper_id">Wicket Keeper</label>
                <select id="wicket_keeper_id" v-model="storedInning.wicket_keeper_id" class="form-control"
                        name="wicket_keeper_id">
                  <option v-for="(player, index) in bowlers" :key="index" :value="player.id">
                    {{ player.en_first_name }}
                    {{ player.en_last_name }}
                  </option>
                </select>
              </div>
            </div>

            <div class="col-12 col-md-6" v-if="!storedInning.id">
              <div class="form-group">
                <label for="number">Inning</label>
                <select id="number" v-model="storedInning.number" class="form-control" name="number">
                  <option value="1">First</option>
                  <option value="2">Second</option>
                  <option value="3">Third</option>
                  <option value="4">Fourth</option>
                  <option value="5">Fifth</option>
                </select>
              </div>
            </div>


            <div class="col-12 col-md-6" v-if="!storedInning.id">
              <div class="form-group">
                <label for="name">Inning Name</label>
                <input id="name" v-model="storedInning.name" class="form-control" name="name">
              </div>
            </div>

            <div class="col-12">
              <button class="btn btn-primary float-right" @click.prevent="storeInning" v-if="!storedInning.id">
                Save
              </button>

            </div>

          </div>

          <div class="row" v-if="storedInning.id">
            <div class="col-12 col-md-6">
              <div class="form-group">
                <label for="reason">Reason</label>
                <select id="reason" v-model="endInning.reason_id" class="form-control" name="reason">
                  <option value="1">Normal</option>
                  <option value="2">Abnormal</option>
                  <option value="3">Follow On</option>
                </select>
              </div>
            </div>

            <div class="col-6 col-md-3">

              <div class="form-group">
                <label for="over_limit">Over Limit</label>
                <input id="over_limit" v-model="endInning.over_limit" class="form-control" name="over_limit">
              </div>

            </div>
            <div class="col-9 col-md-3">

              <div class="form-group">
                <label for="target">Target</label>
                <input id="target" v-model="endInning.target" class="form-control" name="target">
              </div>

            </div>

            <div class="col-12">
              <div class="form-group">
                <label for="comment">Comment</label>
                <textarea id="comment" v-model="endInning.comment" class="form-control" name="comment"></textarea>
              </div>
            </div>
            <div class="col-12">
              <button class="btn btn-primary btn-block float-right" @click.prevent="storeEndInning">
                End Inning
              </button>
            </div>

          </div>

        </div>


      </div>
    </div>


  </div>

  <div class="row">
    <div class="col-12">
      <!--      <AppBottomSection/>-->

      <div class="card">
        <div class="card-body">

          <AppScoreCard/>


          <hr>

          <AppMatchNote/>

          <hr>

          <AppMatchResult :defaultStatus="1"/>

        </div>


      </div>
    </div>
  </div>

</template>
<script>
import {
  declare,
  initInning,
  loadSelectedMatchStatus,
  removeFromCrease,
  storeBowler,
  storeEndInning,
  storeInning,
  storeStriker,
  updateMatchStatus
} from "@/services/launchMatch/StartInning";
import {showDelays} from "@/services/lookup/DelayType";
import {showBreaks} from "@/services/lookup/BreakType";
import {useToast} from 'vue-toastification';
import {StartInning} from "@/mixins/launchMatch/StartInning";
import {Scoring} from "@/mixins/launchMatch/Scoring"
import draggable from 'vuedraggable'
import ScoringCom from "@/components/scoringAndPerformance/launchMatch/components/Scoring";
import ScoreCard from "@/components/scoringAndPerformance/launchMatch/components/ScoreCard";
import MatchNote from "@/components/scoringAndPerformance/launchMatch/components/MatchNote";
import MatchResult from "@/components/scoringAndPerformance/launchMatch/components/MatchResult";
// import defaultLayout from '@/components/layouts/defaultLayout.vue';

export default {
  mixins: [StartInning, Scoring],

  data() {
    return {
      toast: useToast(),
      inning: {},
      showBowlers: false,
      endInning: {},
      delays: [],
      breaks: [],
      selectedMatchStatus: {},
    }
  },
  components: {
    draggable,
    AppScoring: ScoringCom,
    AppScoreCard: ScoreCard,
    AppMatchNote: MatchNote,
    AppMatchResult: MatchResult,
  },
  computed: {
    cBowlers() {
      let bowlers = [];
      for (let i = 0; i < this.bowlers.length; i++) {

        if (this.twoBowlers(this.bowlers[i].id)) {
          bowlers.push(this.bowlers[i]);
        }

      }

      return bowlers;
    },


    outPlayers() {
      let outPlayers = [];

      for (let i = 0; i < this.strikers.length; i++) {
        if (this.strikers[i].player_playing_status_id !== 1) {
          outPlayers.push(this.strikers[i]);
        }
      }

      return outPlayers;
    }
  },

  methods: {

    // selectBowler(bowler) {
    //   this.bowler = bowler;
    //   this.loadStrike(this.storedInning.id, this.bowler.id);
    //
    // },

    twoBowlers(bowler_id) {
      for (let i = 0; i < this.bowler.length; i++) {
        if (this.bowler[i].id === bowler_id) {
          return false;
        }
      }

      return true;
    },

    changePlayingStatus(player_id, player_playing_status_id) {
      let data = {
        inning_id: this.storedInning.id,
        player_id,
        player_playing_status_id,
      };

      this.$swal({

        title: 'Are you sure?',

        text: "You won't be able to revert this!",

        type: 'warning',

        showCancelButton: true,

        confirmButtonColor: '#d33',

        cancelButtonColor: '#3085d6',

        confirmButtonText: player_playing_status_id === 4 ? 'Yes, declare it!' : 'Yes, Retired Hurt it!',

      }).then((result) => {

        if (result.value) {

          declare(data).then(() => {
            this.initInning();
          });

        }

      });


    },


    initInning() {
      this.strikers = [];
      this.batsmen = [];
      this.bowlers = [];
      this.bowlingTeam = {};
      this.battingTeam = {};
      this.storedInning = {};
      this.wicketKeeperID = 0;

      if (this.fixture.fixture_id) {

        this.toast.info('Initializing Inning...');

        initInning(this.fixture.fixture_id).then(response => {

          this.toast.clear();
          this.batsmen = response.data.batsmen;
          this.wicketKeeperID = response.data.wicket_keeper_id;
          this.allBatsmen = response.data.all_batsmen;
          this.bowlers = response.data.bowlers;
          this.bowlingTeam = response.data.bowling_team;
          this.battingTeam = response.data.batting_team;
          this.storedInning = !response.data.inning ? {} : response.data.inning;
          this.bowler = response.data.two_bowler;
          // this.strikers = response.data.strikers === null ? {} : response.data.strikers;

          //this.initScoring(this.storedInning.id);

          this.initScoring();
          this.loadSelectedMatchStatus();


          // this.loadStrike(this.storedInning.id, this.bowler.id);
        });


      } else {

        this.batsmen = [];
        this.allBatsmen = [];
        this.bowlers = [];
        this.bowlingTeam = {};
        this.battingTeam = {};
        this.storedInning = {};

        this.initScoring();

        this.$swal({
          title: "Fixture Not Identified",
          text: "Please Select Fixture First!",
          icon: "info",
        });

      }
    },

    storeInning() {

      this.toast.info('processing...');

      this.inning.fixture_id = this.fixture.fixture_id;
      this.inning.team_id = this.battingTeam.id;
      this.inning.number = this.storedInning.number;
      this.inning.name = this.storedInning.name;
      this.inning.captain_id = this.storedInning.captain_id;
      this.inning.wicket_keeper_id = this.storedInning.wicket_keeper_id;

      storeInning(this.inning).then(response => {
        this.storedInning = response.data;
        this.toast.clear();

      }, error => {
        this.toast.clear();
        this.toast.error(error.response.data.message);
      });

    },
    storeEndInning() {
      let data = this.endInning;
      data.inning_id = this.storedInning.id;

      this.toast.clear();

      this.toast.info('Ending Inning');

      storeEndInning(data).then(() => {

        this.toast.info('Inning Ended Successfully!');

        this.initInning();

      }, error => {
        this.toast.error(error.response.data.message);
      })

    },
    addToStrike(batsman, index) {

      if (this.strikePlayers.length < 2) {
        let data = {
          inning_id: this.storedInning.id,
          player_id: batsman.id,
        };

        storeStriker(data).then((response) => {
          this.pushStrikers(response.data);
          this.vuexRemoveBatsmen(index);
        }, error => {
          this.toast.error(error.response.data.message);
        });


      } else {
        this.$swal({
          title: "Current Playing Batsmen",
          text: "Only Two Batsmen are Allowed!!!",
          icon: "info",
        });
      }

    },

    addToStrikeAgain(batsman) {
      let data = {
        inning_id: this.storedInning.id,
        player_id: batsman.id,
      };

      storeStriker(data).then(() => {
        // this.pushStrikers(response.data);
        this.initInning();
      }, error => {
        this.toast.error(error.response.data.message);
      });

    },

    addToBowlers(bowler, index) {


      if (this.bowler.length < 2) {

        let data = {
          inning_id: this.storedInning.id,
          player_id: bowler.id,
        };

        this.toast.info('Adding New Bowler', {
          duration: 30000,
        })

        storeBowler(data).then((response) => {


          this.pushBowler(response.data.bowler);

          this.vuexRemoveBowler(index);

          this.toast.clear();

          this.initScoring();

        }, error => {

          this.toast.clear();

          this.toast.error(error.response.data.message);


        });


      } else {

        this.$swal({
          title: "Current Playing Bowlers",
          text: "Only Two Bowler are Allowed!!!",
          icon: "info",
        });

      }
    },

    removeFromCrease(batsman) {

      let data = {
        inning_id: this.storedInning.id,
        player_id: batsman.player_id,
      };

      this.toast.info('Removing Crease Batsman');

      removeFromCrease(data).then(() => {
        this.toast.clear();
        this.toast.success('Removed Successfully!');
        this.initInning();

      }, error => {
        this.toast.clear();
        this.toast.error(error.response.data.message);
      });

    },

    loadDelays() {

      showDelays().then(response => {
        this.delays = response.data;

      }, error => {
        this.toast.error(error.response.data.message);
      })
    },
    loadBreaks() {

      showBreaks().then(response => {
        this.breaks = response.data;

      }, error => {
        this.toast.error(error.response.data.message);
      })
    },

    updateMatchStatus(data) {

      data.fixture_id = this.fixture.fixture_id;
      data.type = 'match-status';

      updateMatchStatus(data).then(response => {

            this.selectedMatchStatus = response.data;

            console.log(this.selectedMatchStatus);
          },
          error => {
            this.toast.clear();
            this.toast.error(error.response.data.message);
          });
    },
    loadSelectedMatchStatus() {

      loadSelectedMatchStatus(this.fixture.fixture_id).then(response => {
        this.selectedMatchStatus = response.data;
        console.log(this.selectedMatchStatus);
      }, error => {
        this.toast.clear();
        this.toast.error(error.response.data.message);
      })
    }


  },

  created() {


    this.initInning();
    this.loadDelays();
    this.loadBreaks();


  }


}
</script>
<style>

th, td {
  height: 30px !important;
}

.box {
  border-top: 1px solid rgb(172, 200, 223);
  border-bottom: 1px solid rgb(172, 200, 223);
  border-right: 1px solid rgb(172, 200, 223);
  border-left: 1px solid rgb(172, 200, 223);
  border-radius: 5px;
}

.bg-secondary {
  background-color: #045BA6 !important;
  border-bottom: 3px solid #023C6E;
  border-radius: 5px 5px 0 0;

}

.bg-secondary .media-body .media-title {
  color: #ffffff;
}

.bg-secondary .media-body .text-small {
  color: lighgray;
}


.btn.btn-sm {
  padding: .8rem .2rem;

}

.selected-bowler {
  border-bottom: 2px solid red;
  background-color: #acc8df;
}


@media only screen and (min-width: 700px) {
  .h-340 {
    min-height: 340px !important;
    overflow-y: auto;
    overflow-x: hidden;

  }

  .h2-340 {
    max-height: 466px !important;
    overflow-y: auto;
    overflow-x: hidden;

  }
}


</style>