import API from '@/services/API.js';

export const FullScoreCardMixin = {

    methods: {
        loadFullScoreCard(fixture_id, inning_id) {

            this.showFSC = !this.showFSC;

            API.get('full-score-card?fixture_id=' + fixture_id + '&inning_id=' + inning_id).then(response => {
                console.log(response.data);
            });

        }
    }
}