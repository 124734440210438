<template>
  <div class="section mb-5">

    <head-slot>
      <title>Scoring And Performance | Launch Match</title>
    </head-slot>

    <div class="section-header">

      <h1>Scoring and Performance</h1>

      <div class="section-header-breadcrumb">
        <div class="breadcrumb-item active">
          <a href="#" @click.prevent="">Scoring and Performance</a>
        </div>
        <div class="breadcrumb-item">Launch Match</div>

      </div>

    </div>

    <div class="section-body">
      <div class="row">
        <div class="col-12 col-md-12 pr-0 pl-0">

          <div class="card card-primary">
            <div class="card-header">

              <h4>
                Manage Inning
              </h4>

              <div class="card-header-action">

                <div class="btn-group">

                  <router-link :to="{ name: 'scoring-and-performance-launch-match', params:{section: 'playing-11'} }"
                               active-class="btn-primary-active"
                               class="btn btn-primary">
                    Playing 11 & Toss
                  </router-link>

                  <router-link :to="{ name: 'scoring-and-performance-launch-match', params:{section: 'start-inning'} }"
                               active-class="btn-primary-active" class="btn btn-primary">Start Inning
                  </router-link>


                </div>

              </div>

            </div>
            <div class="card-body">


              <app-playing11 v-if="$route.params.section === 'playing-11'"/>


              <app-start-inning v-if="$route.params.section === 'start-inning'"/>


            </div>
          </div>

        </div>
      </div>
    </div>
  </div>

</template>
<script>
import Playing11 from "@/components/scoringAndPerformance/launchMatch/components/Playing11";
import StartInning from "@/components/scoringAndPerformance/launchMatch/components/StartInning";


export default {
  components: {
    appPlaying11: Playing11,
    appStartInning: StartInning,
  }
}
</script>
<style>
.btn-primary:active, .btn-primary:hover, .btn-primary.disabled:active, .btn-primary.disabled:hover, .btn-primary-active {
  background-color: #394eea !important
}

@media screen and (max-width: 600px) {
  .card-body {
    padding: 0.85em !important;
  }
}

</style>