<template :key="storedInning.id">

  <div class="row">
    <div class="col-12 text-center">
      <div class="lds-ellipsis no-opacity" v-if="loadScore" style="top: 66px; position: absolute;  margin: 0 auto;">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>

      <div class="table-responsive text-left" :class="{'opacity': loadScore}">
        <a :href="'https://cricket.af/match-center/'+fixture.fixture_id +'/'+ (fixture.fixture_title +'-'+ fixture.home_team_name + '-vs-' + fixture.away_team_name + '#live').replace(/ /g,'-').toLocaleLowerCase()"
           class="btn btn-danger pull-right" style="padding: 2px 6px; font-size: 12px; margin-bottom: 5px;"
           target="_blank">
          Watch Live
        </a>
        <table class="table table-left table-sm mb-0 font-10" style="border: none !important; min-width: 100%;">
          <thead>
          <tr>
            <th class="text-capitalize " colspan="1" style="width: 120px;">BATSMEN</th>
            <th class="text-capitalize " colspan="1">R</th>
            <th class="text-capitalize " colspan="1">B</th>
            <th class="text-capitalize " colspan="1">4s</th>
            <th class="text-capitalize " colspan="1">6s</th>
            <th class="text-capitalize " colspan="1">
              SR

              <i class="float-right text-primary fa fa-recycle   mt-1" @click="initScoring();"></i>

            </th>

          </tr>
          </thead>
          <tbody>
          <tr v-if="strikePlayers[0]">
            <td>
              <a href="#" data-hover="" class="player-name"
                 target="_parent" rel="" @click.prevent="">
                <span v-if="strikePlayers[0].en_last_name.length > 1">
                  {{ strikePlayers[0].en_first_name.split(' ')[0].charAt(0) }}
                  <span v-if="strikePlayers[0].en_first_name.split(' ')[1]">
                    {{ strikePlayers[0].en_first_name.split(' ')[1].charAt(0) }}
                  </span>
                </span>
                <span v-else>
                  {{ strikePlayers[0].en_first_name }}
                </span>
                <span>
                  {{ " " + strikePlayers[0].en_last_name }}
                </span>
                <span v-if="strikePlayers[0].striker === 1">*</span>
                <span class="player-style">
                  (
                  <span>{{ strikePlayers[0].batting_style }}</span>

                  )
                </span>
              </a>
              <i id="play" class="espn-icon icon-play-outline-after icon-sm ml-1 cursor-pointer text-danger"></i>
            </td>
            <!--            <td>{{ batsmanRuns(strikers[0].player_id) }}</td>-->
            <td>{{ batsmanRunsScored(strikePlayers[0].id, batsmenLegalRunsScored) }}</td>
            <!--            <td>{{ batsmenBall(strikers[0].player_id) }}</td>-->
            <td>{{ batsmanBallsFaced(strikePlayers[0].id, batsmenFacedLegalBalls) }}</td>
            <!--            <td>{{ batsmen4s(strikers[0].player_id) }}</td>-->
            <td>{{ batsmanBoundaries(strikePlayers[0].id, 4, batsmenLegalRunsScored) }}</td>
            <!--            <td>{{ batsmen6s(strikers[0].player_id) }}</td>-->
            <td>{{ batsmanBoundaries(strikePlayers[0].id, 6, batsmenLegalRunsScored) }}</td>
            <td v-if="batsmanRunsScored(strikePlayers[0].id, batsmenLegalRunsScored) !== 0 && batsmanBallsFaced(strikePlayers[0].id, batsmenFacedLegalBalls) !== 0">

              {{
                ((batsmanRunsScored(strikePlayers[0].id, batsmenLegalRunsScored) * 100) / batsmanBallsFaced(strikePlayers[0].id, batsmenFacedLegalBalls)).toFixed(2)
              }}
            </td>
            <td v-else>
              0
            </td>


          </tr>
          <tr v-if="strikePlayers[1]">
            <td>
              <a href="#" data-hover="" class="player-name" target="_parent" rel="" @click.prevent="">


                <span v-if="strikePlayers[1].en_last_name.length > 1">
                  {{ strikePlayers[1].en_first_name.split(' ')[0].charAt(0) }}
                  <span v-if="strikePlayers[1].en_first_name.split(' ')[1]">
                    {{ strikePlayers[1].en_first_name.split(' ')[1].charAt(0) }}
                  </span>
                </span>
                <span v-else>
                  {{ strikePlayers[1].en_first_name }}
                </span>
                <span>
                  {{ " " + strikePlayers[1].en_last_name }}
                </span>


                <span v-if="strikePlayers[1].striker === 1">*</span>
                <span class="player-style">
                  (
                  <span>{{ strikePlayers[1].batting_style }}</span>
                  )
                </span>
              </a>
            </td>
            <!--            <td>{{ batsmanRuns(strikers[1].player_id) }}</td>-->
            <td>{{ batsmanRunsScored(strikePlayers[1].id, batsmenLegalRunsScored) }}</td>
            <!--            <td>{{ batsmenBall(strikers[1].player_id) }}</td>-->
            <td>{{ batsmanBallsFaced(strikePlayers[1].id, batsmenFacedLegalBalls) }}</td>
            <!--            <td>{{ batsmen4s(strikers[1].player_id) }}</td>-->
            <td>{{ batsmanBoundaries(strikePlayers[1].id, 4, batsmenLegalRunsScored) }}</td>
            <td>{{ batsmanBoundaries(strikePlayers[1].id, 6, batsmenLegalRunsScored) }}</td>
            <td v-if="batsmanRunsScored(strikePlayers[1].id, batsmenLegalRunsScored) !== 0 && batsmanBallsFaced(strikePlayers[1].id, batsmenFacedLegalBalls) !== 0">

              {{
                ((batsmanRunsScored(strikePlayers[1].id, batsmenLegalRunsScored) * 100) / batsmanBallsFaced(strikePlayers[1].id, batsmenFacedLegalBalls)).toFixed(2)
              }}

            </td>
            <td v-else>0</td>

          </tr>
          </tbody>
          <thead>
          <tr>
            <th class="text-capitalize " colspan="1">BOWLERS</th>
            <th class="text-capitalize " colspan="1">O</th>
            <th class="text-capitalize " colspan="1">M</th>
            <th class="text-capitalize " colspan="1">R</th>
            <th class="text-capitalize " colspan="1">W</th>
            <th class="text-capitalize " colspan="1">ECON</th>

          </tr>
          </thead>
          <tbody>

          <tr v-for="(b,index) in bowler" :key="index">
            <td @click="updateBowlingStatus(b.id,storedInning.id)">

              <button class="btn btn-danger btn-sm mr-1" v-if="storedInning.id"
                      @click.prevent="removeFromBowlers(b)">
                <i class="fas fa-angle-double-left"></i>
              </button>

              <a href="#" data-hover="" class="player-name" target="_parent" rel="" @click.prevent="">
                <span class="status open" v-if="b.status === 1"></span>

                <span v-if="b.en_last_name.length > 1">
                    {{ b.en_first_name.split(' ')[0].charAt(0) }}
                    <span v-if="b.en_first_name.split(' ')[1]">
                      {{ b.en_first_name.split(' ')[1].charAt(0) }}
                    </span>
                  </span>
                <span v-else>
                    {{ b.en_first_name }}
                  </span>
                <span>
                    {{ " " + b.en_last_name }}
                  </span>

                <span class="player-style">
                  (
                  <span v-if="b.bowling_type !== 'SLO' ">
                    {{ b.bowling_style }}
                    </span>
                    <span v-if="b.bowling_type">
                      {{ b.bowling_type }}
                    </span>
                  )
                </span>


              </a>

            </td>

            <td v-if="bowlerLegalBallsBowledF(b.id) > 5" class="vertical-middle">
              {{ Math.trunc(bowlerLegalBallsBowledF(b.id) / 6) + '.' + (bowlerLegalBallsBowledF(b.id) % 6) }}
            </td>
            <td v-else class="vertical-middle">
              0<span v-if="bowlerLegalBallsBowledF(b.id)">.{{ bowlerLegalBallsBowledF(b.id) }}</span>
            </td>

            <td class="vertical-middle">{{ bowlerMaidenOvers(b.id, bowlerLegalRunsGiven) }}</td>
            <td class="vertical-middle">{{ bowlerRuns(b.id, bowlerGivenRuns) }}</td>
            <td class="vertical-middle">{{ bowlerWickets(b.id, bowlerPerformance) }}</td>
            <td v-if="bowlerRuns(b.id, bowlerGivenRuns) !== 0 && bowlerOvers(b.id, bowlerPerformance) !== 0"
                class="vertical-middle">
              {{ (bowlerRuns(b.id, bowlerGivenRuns) / (bowlerLegalBallsBowledF(b.id) / 6)).toFixed(2) }}
            </td>

          </tr>


          </tbody>
        </table>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12 text-center mt-1">

      <div class="table-responsive">
        <table class="table table-left table-sm mb-0 font-10" style="border: none !important; min-width: 100%;">
          <thead>
          <tr style="border-bottom-color: #acc8df;">
            <th class="text-capitalize text-left" colspan="1" style="background-color: #acc8df;">
              Recent Runs

              <span class="pull-right" v-if="legalBallsBowled > 5">
                ({{ Math.trunc(legalBallsBowled / 6) + '.' + (legalBallsBowled % 6) }})
              </span>
              <span class="pull-right" v-else>
               ( 0.{{ legalBallsBowled }})
              </span>
              <span class="pull-right">
               &nbsp; {{ totalInningRuns }} / {{ totalInningWickets }} &nbsp;
              </span>

            </th>
          </tr>

          </thead>
          <tbody>
          <tr>
            <td colspan="2" dir="rtl" class="text-center">

              <div v-for="(runs,index) in recentRuns" :key="index" class="d-inline text-center "
                   style="width: 20px !important; "
                   dir="rtl">
                <span v-if="runs.bowl === 6" dir="rtl">
                    &nbsp;|
                </span>
                <button class="btn btn-outline-secondary mr-1 d-inline btn-round position-relative mt-3" dir="rtl"
                        :class="{'btn-danger': runs.wicket_type_id > 0, 'btn-info': runs.runs_type === 2}">


                  <span class="text-center"
                        v-if="runs.runs === 0 && runs.wicket_type_id === 0">0</span>
                  <!--                  if wicket-->
                  <span v-if="runs.wicket_type_id > 0">W</span>
                  <!--                  if run out-->
                  <span v-if="runs.wicket_type_id === 4" class="wicket-runs">{{ runs.runs }}</span>

                  <span v-if="runs.runs > 0 && runs.wicket_type_id === 0">
                    {{ runs.runs }}
                  </span>

                  <span v-if="

                  runs.extra_runs_type_id === 1
                  || runs.extra_runs_type_id === 2
                  || runs.extra_runs_type_id === 3
                  || runs.extra_runs_type_id === 4
                  || runs.extra_runs_type_id === 5
                  || runs.extra_runs_type_id === 6
                  || runs.extra_runs_type_id === 7
                  " class="extra-runs" :class="{'extra-runs-wicket': runs.wicket_type_id === 4}">

                    {{ runs.extra_runs }}<small v-if="runs.extra_runs_type_id === 1" class="font-6">LB</small>
                    <small v-if="runs.extra_runs_type_id === 2" class="font-6">B</small>
                    <small v-if="runs.extra_runs_type_id === 3" class="font-6">W</small>
                    <small v-if="runs.extra_runs_type_id === 4" class="font-6">NB</small>
                    <small v-if="runs.extra_runs_type_id === 5" class="font-6">LNB</small>
                    <small v-if="runs.extra_runs_type_id === 6" class="font-6">BNB</small>
                    <small v-if="runs.extra_runs_type_id === 7" class="font-6">OvNB</small>
                  </span>

                  <p class="over-ball">
                    {{ runs.over - 1 }}.{{ runs.bowl }}

                  </p>


                </button>

                <!--                <span v-if="recentRuns[index + 1]">-->


              </div>


            </td>
          </tr>
          <tr>
            <td class="text-center" style="height: 25px !important;">

            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <hr>

    </div>
  </div>


  <div class="row">
    <div class="col-12 text-center">
      <button class="btn btn-primary m-1"
              @click.prevent="wicket"
              :disabled="loadScore"
              :class="{'selected-btn': showWicket}">
        Wicket
      </button>

      <button class="btn btn-primary m-1" :disabled="loadScore"
              @click="bowl({runs:0, runs_type: 1})">
        Dot
      </button>
      <button class="btn btn-primary m-1"
              :disabled="loadScore"
              @click="bowl({runs:1, runs_type: 1})">
        1
      </button>
      <button class="btn btn-primary m-1"
              :disabled="loadScore"
              @click="bowl({runs:2, runs_type: 1})">
        2
      </button>
      <button class="btn btn-primary m-1"
              :disabled="loadScore"
              @click="bowl({runs:3, runs_type: 1})">
        3
      </button>
      <button class="btn btn-primary m-1"
              :disabled="loadScore"
              @click="bowl({runs:4, runs_type: 1})">
        4
      </button>
      <button class="btn btn-primary m-1"
              :disabled="loadScore"
              @click="bowl({runs:4, runs_type: 2})">
        4s
      </button>
      <button class="btn btn-primary m-1"
              :disabled="loadScore"
              @click="bowl({runs:6, runs_type: 2})">
        6s
      </button>
      <button class="btn btn-primary m-1" @click="undo" :disabled="loadScore">
        <i class="fa fa-undo"></i> Undo
      </button>

    </div>
    <div class="col-12 text-center">

      <button class="btn btn-primary m-1"
              @click.prevent="legBye"
              :disabled="loadScore"
              :class="{'selected-btn': showLegBye}">
        LB
      </button>
      <button class="btn btn-primary m-1"
              @click.prevent="bye"
              :disabled="loadScore"
              :class="{'selected-btn': showBye}"
      >Bye
      </button>
      <button class="btn btn-primary m-1"
              @click.prevent="wide"
              :disabled="loadScore"
              :class="{'selected-btn': showWide}"
      >Wide
      </button>
      <button class="btn btn-primary m-1"
              @click.prevent="noBall"
              :disabled="loadScore"
              :class="{'selected-btn': showNoBall}">
        NB
      </button>
      <button class="btn btn-primary m-1" :disabled="loadScore">Penalty</button>
      <button class="btn btn-primary m-1"
              @click.prevent="overThrow"
              :disabled="loadScore"
              :class="{'selected-btn': showOverThrow}"
      >Ov.Throw
      </button>

    </div>
  </div>


  <div class="row">
    <div class="col-12">
      <div class="wicket options text-center pt-1 pb-4" :class="{'d-none': !showWicket}">
        <div class="row pb-2">
          <div class="col-2 text-left pl-4">
            <i class="fa fa-times " @click="showWicket = false; fielder_id =0"></i>
          </div>
        </div>
        <div class="row">
          <div class="col-12 text-left p-4 fielders" :class="{'d-none': !showFielder}">

            <p class="pl-2 col-white mb-0" v-if="wicket_type_id === 4">Select Out Batsmen:</p>

            <ul class="row  pl-4 pr-4 list-unstyled list-unstyled-border user-list" v-if="wicket_type_id === 4">

              <li class="media col-12 col-md-6 p-2 text-left" v-for="(player, index) in strikers"
                  :class="{ 'selected-bowler' : outPlayerId === player.player_id  }"
                  style="background-color: #FFFFFF; border: 1px solid #acc8df;"
                  @click="outPlayerId = player.player_id"
                  :key="index">

                <img alt="image" :src="$store.state.Global.rootCDN + player.photo"
                     class="mr-3 user-img-radious-style user-list-img">
                <div class="media-body">
                  <div class="mt-0 font-weight-bold">

                    {{ player.en_first_name + " " + player.en_last_name }}

                  </div>
                  <div class="text-small">Bowler</div>
                </div>

              </li>


            </ul>
            <p class="pl-2 col-white mb-0">Fielders: </p>

            <ul class="row  pl-4 pr-4 list-unstyled list-unstyled-border user-list" id="message-list">

              <li class="media col-12 col-md-6 p-2 text-left" v-for="(player, index) in bowlers"
                  :class="{ 'selected-bowler' : fielder_id === player.id  }"
                  style="background-color: #FFFFFF; border: 1px solid #acc8df;"
                  @click="wicketTaken({runs:0, runs_type: 1, wicket_type_id: wicket_type_id, fielder_id: player.id})"
                  :key="index">

                <img alt="image" :src="$store.state.Global.rootCDN + player.photo"
                     class="mr-3 user-img-radious-style user-list-img">
                <div class="media-body">
                  <div class="mt-0 font-weight-bold">
                    {{ player.en_first_name + " " + player.en_last_name }}

                  </div>
                  <div class="text-small">Bowler</div>
                </div>

              </li>


            </ul>
          </div>

          <div class="col-12">

            <button class="btn btn-primary m-1"
                    :disabled="loadScore"
                    @click="showFielder = !showFielder; wicket_type_id = 1">
              Caught
            </button>

            <button class="btn btn-primary m-1"
                    :disabled="loadScore"
                    @click="wicketTaken({runs:0, runs_type: 1, wicket_type_id: 2})">
              LBW
            </button>
            <button class="btn btn-primary m-1"
                    :disabled="loadScore"
                    @click="wicketTaken({runs:0, runs_type: 1, wicket_type_id: 3})">
              Bowled
            </button>
            <button class="btn btn-primary m-1"
                    :disabled="loadScore"
                    @click="showFielder = !showFielder; wicket_type_id = 4">
              Run Out
            </button>
            <button class="btn btn-primary m-1"
                    :disabled="loadScore"
                    @click="wicketTaken({runs:0, runs_type: 1, wicket_type_id: 5, fielder_id: wicketKeeperID})">
              Stumped
            </button>

            <button class="btn btn-primary m-1"
                    :disabled="loadScore"
                    @click="wicketTaken({runs:0, runs_type: 1, wicket_type_id: 6})">
              Hit Wicket
            </button>

            <button class="btn btn-primary m-1"
                    :disabled="loadScore"
                    @click="wicketTaken({runs:0, runs_type: 1, wicket_type_id: 7})">
              Hit Ball Twice
            </button>
            <button class="btn btn-primary m-1"
                    :disabled="loadScore"
                    @click="wicketTaken({runs:0, runs_type: 1, wicket_type_id: 8})">
              Handled Ball
            </button>
            <button class="btn btn-primary m-1"
                    :disabled="loadScore"
                    @click="wicketTaken({runs:0, runs_type: 1, wicket_type_id: 9})">
              Timed Out
            </button>
            <button class="btn btn-primary m-1"
                    :disabled="loadScore"
                    @click="wicketTaken({runs:0, runs_type: 1, wicket_type_id: 10})">
              Obstructing The Field
            </button>
            <button class="btn btn-primary m-1"
                    :disabled="loadScore"
                    @click="wicketTaken({runs:0, runs_type: 1, wicket_type_id: 11, fielder_id: selectedBowler.id})">
              Mankad
            </button>

          </div>

        </div>


      </div>

      <div class="wide options text-center pt-1 pb-4" :class="{'d-none': !showWide}">
        <div class="row pb-2">
          <div class="col-2 text-left pl-4">
            <i class="fa fa-times " @click="showWide = false"></i>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <button class="btn btn-primary m-1"
                    :disabled="loadScore"
                    @click="bowl({runs:0, runs_type: 3, extra_runs: 1, extra_runs_type_id: 3})"
            >
              Wd
            </button>
            <button class="btn btn-primary m-1"
                    :disabled="loadScore"
                    @click="bowl({runs: 0, runs_type: 3, extra_runs: 2, extra_runs_type_id: 3})">
              1 + Wd
            </button>
            <button class="btn btn-primary m-1"
                    :disabled="loadScore"
                    @click="bowl({runs: 0, runs_type: 3, extra_runs: 3, extra_runs_type_id: 3})">
              2 + Wd
            </button>
            <button class="btn btn-primary m-1"
                    :disabled="loadScore"
                    @click="bowl({runs: 0, runs_type: 3, extra_runs: 4, extra_runs_type_id: 3})">
              3 + Wd
            </button>
            <button class="btn btn-primary m-1"
                    :disabled="loadScore"
                    @click="bowl({runs: 0, runs_type: 3, extra_runs: 5, extra_runs_type_id: 3})">
              4 + Wd
            </button>
            <button class="btn btn-primary m-1"
                    :disabled="loadScore"
                    @click="bowl({runs: 0, runs_type: 3, extra_runs: 6, extra_runs_type_id: 3})">
              5 + Wd
            </button>
            <button class="btn btn-primary m-1"
                    :disabled="loadScore"
                    @click="bowl({runs: 0, runs_type: 3, extra_runs: 7, extra_runs_type_id: 3})">
              6 + Wd
            </button>

          </div>
        </div>

      </div>
      <div class="lb options text-center pt-1 pb-4" :class="{'d-none': !showLegBye}">
        <div class="row pb-2">
          <div class="col-2 text-left pl-4">
            <i class="fa fa-times" @click="showLegBye = false"></i>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <button class="btn btn-primary m-1"
                    :disabled="loadScore"
                    @click="bowl({runs:0, runs_type: 3, extra_runs: 1, extra_runs_type_id: 1})">
              1 LB
            </button>
            <button class="btn btn-primary m-1"
                    :disabled="loadScore"
                    @click="bowl({runs:0, runs_type: 3, extra_runs: 2, extra_runs_type_id: 1})">
              2 LB
            </button>
            <button class="btn btn-primary m-1"
                    :disabled="loadScore"
                    @click="bowl({runs:0, runs_type: 3, extra_runs: 3, extra_runs_type_id: 1})">
              3 LB
            </button>
            <button class="btn btn-primary m-1"
                    :disabled="loadScore"
                    @click="bowl({runs:0, runs_type: 3, extra_runs: 4, extra_runs_type_id: 1})">
              4 LB
            </button>
            <button class="btn btn-primary m-1"
                    :disabled="loadScore"
                    @click="bowl({runs:0, runs_type: 3, extra_runs: 5, extra_runs_type_id: 1})">
              5 LB
            </button>
            <button class="btn btn-primary m-1"
                    :disabled="loadScore"
                    @click="bowl({runs:0, runs_type: 3, extra_runs: 6, extra_runs_type_id: 1})">
              6 LB
            </button>
            <button class="btn btn-primary m-1"
                    :disabled="loadScore"
                    @click="bowl({runs:0, runs_type: 3, extra_runs: 7, extra_runs_type_id: 1})">
              7 LB
            </button>
          </div>
        </div>


      </div>

      <div class="nb options text-center pt-1 pb-4" :class="{'d-none': !showNoBall}">
        <div class="row pb-2">
          <div class="col-2 text-left pl-4">
            <i class="fa fa-times " @click="showNoBall = false"></i>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <button class="btn btn-primary m-1"
                    :disabled="loadScore"
                    @click="bowl({runs:0, runs_type: 3, extra_runs: 1, extra_runs_type_id: 4})">
              NB
            </button>
            <button class="btn btn-primary m-1"
                    :disabled="loadScore"
                    @click="bowl({runs:1, runs_type: 1, extra_runs: 1, extra_runs_type_id: 4})">
              1 NB
            </button>
            <button class="btn btn-primary m-1"
                    :disabled="loadScore"
                    @click="bowl({runs:2, runs_type: 1, extra_runs: 1, extra_runs_type_id: 4})">
              2 NB
            </button>
            <button class="btn btn-primary m-1"
                    :disabled="loadScore"
                    @click="bowl({runs:3, runs_type: 1, extra_runs: 1, extra_runs_type_id: 4})">
              3 NB
            </button>
            <button class="btn btn-primary m-1"
                    :disabled="loadScore"
                    @click="bowl({runs:4, runs_type: 1, extra_runs: 1, extra_runs_type_id: 4})">
              4 NB
            </button>

            <button class="btn btn-primary m-1"
                    :disabled="loadScore"
                    @click="bowl({runs:4, runs_type: 2, extra_runs: 1, extra_runs_type_id: 4})">
              4s NB
            </button>

            <button class="btn btn-primary m-1"
                    :disabled="loadScore"
                    @click="bowl({runs:5, runs_type: 1, extra_runs: 1, extra_runs_type_id: 4})">
              5 NB
            </button>

            <button class="btn btn-primary m-1"
                    :disabled="loadScore"
                    @click="bowl({runs:6, runs_type: 1, extra_runs: 1, extra_runs_type_id: 4})">
              6 NB
            </button>
            <button class="btn btn-primary m-1"
                    :disabled="loadScore"
                    @click="bowl({runs:6, runs_type: 2, extra_runs: 1, extra_runs_type_id: 4})">
              6s NB
            </button>

            <button class="btn btn-primary m-1" @click.prevent="NoBallLegBye">Leg Byes</button>
            <button class="btn btn-primary m-1" @click.prevent="noBallByes">Byes</button>
            <button class="btn btn-primary m-1" @click.prevent="noBallOverThrow">Ov.Throw</button>
          </div>
        </div>


      </div>
      <div class="lnb options text-center pt-1 pb-4" :class="{'d-none': !showNoBallLegBye}">
        <div class="row pb-2">
          <div class="col-2 text-left pl-4">
            <i class="fa fa-times" @click="showNoBallLegBye = false"></i>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <button class="btn btn-primary m-1"
                    :disabled="loadScore"
                    @click="bowl({runs:0, runs_type: 3, extra_runs: 2, extra_runs_type_id: 5})">
              1L NB
            </button>
            <button class="btn btn-primary m-1"
                    :disabled="loadScore"
                    @click="bowl({runs:0, runs_type: 3, extra_runs: 3, extra_runs_type_id: 5})">
              2L NB
            </button>
            <button class="btn btn-primary m-1"
                    :disabled="loadScore"
                    @click="bowl({runs:0, runs_type: 3, extra_runs: 4, extra_runs_type_id: 5})">
              3L NB
            </button>
            <button class="btn btn-primary m-1"
                    :disabled="loadScore"
                    @click="bowl({runs:0, runs_type: 3, extra_runs: 5, extra_runs_type_id: 5})">
              4L NB
            </button>
            <button class="btn btn-primary m-1"
                    :disabled="loadScore"
                    @click="bowl({runs:0, runs_type: 3, extra_runs: 6, extra_runs_type_id: 5})">
              5L NB
            </button>
            <button class="btn btn-primary m-1"
                    :disabled="loadScore"
                    @click="bowl({runs:0, runs_type: 3, extra_runs: 7, extra_runs_type_id: 5})">
              6L NB
            </button>
            <button class="btn btn-primary m-1"
                    :disabled="loadScore"
                    @click="bowl({runs:0, runs_type: 3, extra_runs: 8, extra_runs_type_id: 5})">
              7L NB
            </button>
          </div>
        </div>

      </div>
      <div class="nb-over-throw options text-center pt-1 pb-4" :class="{'d-none': !showOverThrowNoBall}">
        <div class="row pb-2">
          <div class="col-2 text-left pl-4">
            <i class="fa fa-times " @click="showOverThrowNoBall = false"></i>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <button class="btn btn-primary m-1"
                    :disabled="loadScore"
                    @click="bowl({runs:0, runs_type: 3, extra_runs: 4, extra_runs_type_id: 7})">
              4 Ov NB
            </button>
            <button class="btn btn-primary m-1"
                    :disabled="loadScore"
                    @click="bowl({runs:0, runs_type: 3, extra_runs: 5, extra_runs_type_id: 7})">
              5 Ov NB
            </button>
            <button class="btn btn-primary m-1"
                    :disabled="loadScore"
                    @click="bowl({runs:0, runs_type: 3, extra_runs: 6, extra_runs_type_id: 7})">
              6 Ov NB
            </button>
            <button class="btn btn-primary m-1"
                    :disabled="loadScore"
                    @click="bowl({runs:0, runs_type: 3, extra_runs: 7, extra_runs_type_id: 7})">
              7 Ov NB
            </button>
            <button class="btn btn-primary m-1"
                    :disabled="loadScore"
                    @click="bowl({runs:0, runs_type: 3, extra_runs: 8, extra_runs_type_id: 7})">
              8 Ov NB
            </button>
            <button class="btn btn-primary m-1"
                    :disabled="loadScore"
                    @click="bowl({runs:0, runs_type: 3, extra_runs: 9, extra_runs_type_id: 7})">
              9 Ov NB
            </button>
            <button class="btn btn-primary m-1"
                    :disabled="loadScore"
                    @click="bowl({runs:0, runs_type: 3, extra_runs: 10, extra_runs_type_id: 7})">
              10 Ov NB
            </button>
          </div>

        </div>
      </div>

      <div class="bye options text-center pt-1 pb-4" :class="{'d-none': !showBye}">
        <div class="row pb-2">
          <div class="col-2 text-left pl-4">
            <i class="fa fa-times" @click="showBye = false"></i>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <button class="btn btn-primary m-1"
                    :disabled="loadScore"
                    @click="bowl({runs:0, runs_type: 3, extra_runs: 1, extra_runs_type_id: 2})">
              1 B
            </button>
            <button class="btn btn-primary m-1"
                    :disabled="loadScore"
                    @click="bowl({runs:0, runs_type: 3, extra_runs: 2, extra_runs_type_id: 2})">
              2 B
            </button>
            <button class="btn btn-primary m-1"
                    :disabled="loadScore"
                    @click="bowl({runs:0, runs_type: 3, extra_runs: 3, extra_runs_type_id: 2})">
              3 B
            </button>
            <button class="btn btn-primary m-1"
                    :disabled="loadScore"
                    @click="bowl({runs:0, runs_type: 3, extra_runs: 4, extra_runs_type_id: 2})">
              4 B
            </button>
            <button class="btn btn-primary m-1"
                    :disabled="loadScore"
                    @click="bowl({runs:0, runs_type: 3, extra_runs: 5, extra_runs_type_id: 2})">
              5 B
            </button>
            <button class="btn btn-primary m-1"
                    :disabled="loadScore"
                    @click="bowl({runs:0, runs_type: 3, extra_runs: 6, extra_runs_type_id: 2})">
              6 B
            </button>
            <button class="btn btn-primary m-1"
                    :disabled="loadScore"
                    @click="bowl({runs:0, runs_type: 3, extra_runs: 7, extra_runs_type_id: 2})">
              7 B
            </button>
          </div>
        </div>

      </div>
      <div class="nb-byes options text-center pt-1 pb-4" :class="{'d-none': !showNoBallByes}">
        <div class="row pb-2">
          <div class="col-2 text-left pl-4">
            <i class="fa fa-times " @click="showNoBallByes = false"></i>
          </div>
        </div>


        <div class="row">
          <div class="col-12">
            <button class="btn btn-primary m-1"
                    :disabled="loadScore"
                    @click="bowl({runs:0, runs_type: 3, extra_runs: 2, extra_runs_type_id: 6})">
              1B NB
            </button>
            <button class="btn btn-primary m-1"
                    :disabled="loadScore"
                    @click="bowl({runs:0, runs_type: 3, extra_runs: 3, extra_runs_type_id: 6})">
              2B NB
            </button>
            <button class="btn btn-primary m-1"
                    :disabled="loadScore"
                    @click="bowl({runs:0, runs_type: 3, extra_runs: 4, extra_runs_type_id: 6})">
              3B NB
            </button>
            <button class="btn btn-primary m-1"
                    :disabled="loadScore"
                    @click="bowl({runs:0, runs_type: 3, extra_runs: 5, extra_runs_type_id: 6})">
              4B NB
            </button>
            <button class="btn btn-primary m-1"
                    :disabled="loadScore"
                    @click="bowl({runs:0, runs_type: 3, extra_runs: 6, extra_runs_type_id: 6})">
              5B NB
            </button>
            <button class="btn btn-primary m-1"
                    :disabled="loadScore"
                    @click="bowl({runs:0, runs_type: 3, extra_runs: 6, extra_runs_type_id: 6})">
              6B NB
            </button>
            <button class="btn btn-primary m-1"
                    :disabled="loadScore"
                    @click="bowl({runs:0, runs_type: 3, extra_runs: 8, extra_runs_type_id: 6})">
              7B NB
            </button>
          </div>
        </div>


      </div>
      <div class="over-throw options text-center pt-1 pb-4" :class="{'d-none': !showOverThrow}">
        <div class="row pb-2">
          <div class="col-2 text-left pl-4">
            <i class="fa fa-times " @click="showOverThrow = false"></i>
          </div>
        </div>


        <div class="row">
          <div class="col-12">
            <button class="btn btn-primary m-1"
                    :disabled="loadScore"
                    @click="bowl({runs:1, runs_type: 1})">
              1 Ov.Throw
            </button>
            <button class="btn btn-primary m-1"
                    :disabled="loadScore"
                    @click="bowl({runs:2, runs_type: 1})">
              2 Ov.Throw
            </button>
            <button class="btn btn-primary m-1"
                    :disabled="loadScore"
                    @click="bowl({runs:3, runs_type: 1})">
              3 Ov.Throw
            </button>
            <button class="btn btn-primary m-1"
                    :disabled="loadScore"
                    @click="bowl({runs:4, runs_type: 1})">
              4 Ov.Throw
            </button>
            <button class="btn btn-primary m-1"
                    :disabled="loadScore"
                    @click="bowl({runs:5, runs_type: 1})">
              5 Ov.Throw
            </button>
            <button class="btn btn-primary m-1"
                    :disabled="loadScore"
                    @click="bowl({runs:6, runs_type: 1})">
              6 Ov.Throw
            </button>
            <button class="btn btn-primary m-1"
                    :disabled="loadScore"
                    @click="bowl({runs:7, runs_type: 1})">
              7 Ov.Throw
            </button>
            <button class="btn btn-primary m-1"
                    :disabled="loadScore"
                    @click="bowl({runs:8, runs_type: 1})">
              8 Ov.Throw
            </button>
            <button class="btn btn-primary m-1"
                    :disabled="loadScore"
                    @click="bowl({runs:9, runs_type: 1})">
              9 Ov.Throw
            </button>
            <button class="btn btn-primary m-1"
                    :disabled="loadScore"
                    @click="bowl({runs:10, runs_type: 1})">
              10 Ov.Throw
            </button>
          </div>
        </div>

      </div>
    </div>
  </div>


</template>
<script>

import {StartInning} from "@/mixins/launchMatch/StartInning";
import {Scoring} from "@/mixins/launchMatch/Scoring";
import {Performance} from "@/mixins/launchMatch/Performance";
import {removeBowler, updateBowlingStatus} from "@/services/launchMatch/StartInning";


export default {

  mixins: [StartInning, Performance, Scoring],


  data() {

    return {

      showWicket: false,
      showWide: false,
      showLegBye: false,
      showBye: false,
      showNoBall: false,
      showOverThrow: false,
      showNoBallByes: false,
      showNoBallLegBye: false,
      showOverThrowNoBall: false,
      fielder_id: 0,

    }

  },

  methods: {
    updateBowlingStatus(bowler_id, inning_id) {

      this.toast.info('Updating Bowling Status', {
        duration: 30000,
      })

      let data = {
        'bowler_id': bowler_id,
        'inning_id': inning_id,

      };

      updateBowlingStatus(data).then((response) => {
        this.bowler = response.data.bowler;
        this.toast.clear();
      }, error => {

        this.toast.clear();
        this.toast.error(error);

      });


    },

    removeFromBowlers(bowler) {

      this.toast.info('Removing Bowler', {
        duration: 30000,
      });

      let data = {
        inning_id: this.storedInning.id,
        bowler_id: bowler.id,
      };


      removeBowler(data).then((response) => {

        this.bowler = response.data.bowler;

        this.pushBowlers(bowler);

        this.toast.clear();

      }, error => {
        this.toast.clear();
        this.toast.error(error)
      });

    },

    getInningResult(inning_id) {
      for (let i = 0; i < this.results.length; i++) {
        if (this.results[i].inning_id === inning_id) {
          return this.results[i];

        }
      }
    },

    closeExtra() {
      this.showWicket = false;
      this.showWide = false;
      this.showLegBye = false;
      this.showBye = false;
      this.showNoBall = false;
      this.showOverThrow = false;
      this.showOverThrowNoBall = false;
      this.showNoBallByes = false;
      this.showNoBallLegBye = false;
      this.fielder_id = 0;
      this.showFielder = false;
    },
    wicket() {
      this.closeExtra();
      this.showWicket = true;
      this.showFielder = false;

    },
    wide() {
      this.closeExtra();
      this.showWide = true;
    },
    legBye() {
      this.closeExtra();
      this.showLegBye = true;
    },
    bye() {
      this.closeExtra();
      this.showBye = true;
    },
    noBall() {
      this.closeExtra();
      this.showNoBall = true;
    },
    overThrow() {
      this.closeExtra();
      this.showOverThrow = true;
    },
    noBallOverThrow() {
      this.showOverThrowNoBall = true;
    },
    noBallByes() {
      this.showNoBallByes = true;
    },
    NoBallLegBye() {

      this.showNoBallLegBye = true;
    }

  },
  created() {
    this.initScoring();
  }
}
</script>
<style scoped>
.selected-btn, .selected-btn:active {
  background-color: #4F5ECE !important;
}

.options {
  background-color: #acc8df;
  border-radius: 5px;
  margin: 10px 0 10px 0;
}

thead tr {
  border-top: none;
  border-bottom: none !important;
  background-color: #acc8df;
}

.btn-outline-secondary {
  padding: .1rem .6rem !important;
}

.selected-bowler {
  border-bottom: 2px solid red !important;
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 10px;
  height: 10px;
}

.lds-ellipsis div {
  position: absolute;
  top: 3px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #045BA6;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}


.extra-runs, .wicket-runs {
  position: absolute;
  border-radius: 50%;
  padding: 0 1px 0 1px;
  color: #fff;
  width: 22px;
  height: 22px;
  font-size: 10px;
  justify-content: center;
  align-items: center;
}

.extra-runs {
  top: -12px;
  left: auto;
  right: -4px;
  background: #045B22;

}

.extra-runs-wicket {
  top: 16px !important;
}

.wicket-runs {
  top: -12px;
  left: auto;
  right: -4px;
  background: #045BA6;
}

.over-ball {
  position: absolute;
  bottom: -37px;
  left: 8px;
  font-size: 7px;
  text-align: center;
  color: gray;
}

.opacity {
  opacity: 0.4;
}

.table tr {
  border-bottom: none;
}

.vertical-middle {
  vertical-align: middle;
}


.status.open:before {
  background-color: #94e185;
  border-color: #78d965;
  box-shadow: 0px 0px 4px 1px #94e185;
}

.status.in-progress:before {
  background-color: #ffc182;
  border-color: #ffb161;
  box-shadow: 0px 0px 4px 1px #ffc182;
}

.status.dead:before {
  background-color: #c9404d;
  border-color: #c42c3b;
  box-shadow: 0px 0px 4px 1px #c9404d;
}

.status:before {
  content: " ";
  display: inline-block;
  width: 7px;
  height: 7px;
  margin-right: 10px;
  border: 1px solid #000;
  border-radius: 7px;
}


</style>