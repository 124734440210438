<template>
  <fieldset v-if="vuexGetFixtureTeam().id">
    <legend>{{ vuexGetFixtureTeam().name }} Won the Toss ?</legend>
    <div class="row">
      <div class="col-12 col-md-4">
        <div class="form-group d-md-inline " style="margin-bottom: 0 !important; display:inline;">
          <label class="custom-switch pl-1">
            <input v-model="yesTheWon" class="custom-switch-input" name="custom-switch-checkbox" type="checkbox"
                   value="1">
            <span class="custom-switch-indicator"></span>
            <span class="custom-switch-description">
          Y.th.Won
        </span>
          </label>
        </div>
      </div>
      <div v-if="yesTheWon" class="col-12 col-md-8">
        <div class="form-group d-md-inline mb-0">
          <div class="custom-control custom-checkbox ml-2 mt-2 d-inline">

            <input id="customCheck1" v-model="electedTo"
                   :checked="vuexGetElectedTo()? vuexGetElectedTo().elected_to == 1 :''"
                   class="custom-control-input" name="elected_to"
                   type="radio" value="1"
                   @change="setElectedTo">
            <label class="custom-control-label " for="customCheck1">Elected to Bat</label>
          </div>
        </div>
        <div class="form-group d-md-inline mb-0">
          <div class="custom-control custom-checkbox ml-2 mt-2 d-inline">
            <input id="customCheck2" v-model="electedTo"
                   :checked="vuexGetElectedTo()? vuexGetElectedTo().elected_to == 2 : ''"
                   class="custom-control-input" name="elected_to"
                   type="radio" value="2" @change="setElectedTo">
            <label class="custom-control-label " for="customCheck2">Elected to Bowl</label>
          </div>
        </div>


      </div>
    </div>
    <div v-if="yesTheWon" class="row">
      <div class="col-12">
        <button class="btn btn-primary btn-sm pull-right m-1" @click.prevent="save">Save</button>
      </div>
    </div>
  </fieldset>

</template>
<script>
import {mapGetters, mapMutations} from 'vuex';
import {storeToss} from "@/services/launchMatch/Match";
import {useToast} from 'vue-toastification';

export default {
  data() {
    return {
      electedTo: '',
      toast: useToast(),
    }
  },
  computed: {
    yesTheWon: {
      get() {
        return this.vuexGetYesTheyWon();
      },
      set(value) {
        this.vuexSetYesTheyWon(value);
      }
    },
  },
  methods: {
    ...mapGetters({
      vuexGetFixtureTeam: 'LaunchMatch/getFixtureTeam',
      vuexGetElectedTo: 'LaunchMatch/getElectedTo',
      vuexGetYesTheyWon: 'LaunchMatch/getYesTheyWon',
      vuexGetFixture: 'LaunchMatch/getFixture',
    }),
    ...mapMutations({
      vuexSetElectedTo: 'LaunchMatch/setElectedTo',
      vuexSetYesTheyWon: 'LaunchMatch/setYesTheyWon',
    }),

    setElectedTo() {

      let data = {
        fixture_id: this.vuexGetFixture().fixture_id,
        team_id: this.vuexGetFixtureTeam().id,
        elected_to: this.electedTo,
      }

      this.vuexSetElectedTo(data);

    },
    save() {
      let data = {
        fixture_id: this.vuexGetFixture().fixture_id,
        team_id: this.vuexGetFixtureTeam().id,
        elected_to: this.vuexGetElectedTo().elected_to,
      };
      this.toast.info('processing...')
      storeToss(data).then(() => {
        this.toast.clear();
        this.toast.success('Toss Create Successfully!')
      }, error => {
        this.toast.clear();
        this.toast.error(error.response.data.message);
      });
    }
  },

}
</script>

<style scoped>
fieldset {
  border: 1px solid #045BA6;
  border-radius: 5px;
  margin: 15px;
}

legend {
  width: 60%;
  border-radius: 5px;
  border: 1px solid #045BA6;
  margin-left: 10px;
  padding: 2px 0 2px 5px;
  font-size: 14px;
}
</style>