import {mapGetters, mapMutations} from 'vuex';
import {changeStrike} from "@/services/launchMatch/StartInning";

export const StartInning = {
    computed: {
        fixture: {
            get() {
                return this.vuexGetFixture();
            }
        },
        battingTeam: {
            get() {
                return this.vuexGetBattingTeam();
            },
            set(value) {
                this.vuexSetBattingTeam(value);
            }
        },
        bowlingTeam: {
            get() {
                return this.vuexGetBowlingTeam()
            },
            set(value) {
                this.vuexSetBowlingTeam(value);
            }
        },

        batsmen: {
            get() {
                return this.vuexGetBatsmen();
            },
            set(value) {
                this.vuexSetBatsmen(value);
            },
        },

        wicketKeeperID: {
            get() {
                return this.vuexGetWicketKeeperID();
            },
            set(value) {
                this.vuexSetWicketKeeperID(value);
            },
        },

        allBatsmen: {
            get() {
                return this.vuexGetAllBatsmen();
            },
            set(value) {
                this.vuexSetAllBatsmen(value);
            },
        },

        bowlers: {
            get() {
                return this.vuexGetBowlers();
            },
            set(value) {
                this.vuexSetBowlers(value);
            },
        },


        strikers: {
            get() {
                return this.vuexGetStrikers();
            },
            set(value) {
                this.vuexSetStrikers(value);
            }
        },

        bowler: {

            get() {
                return this.vuexGetBowler();
            },
            set(value) {
                this.vuexSetBowler(value);
            }

        },
        strikePlayers() {
            let strikePlayers = [];

            for (let i = 0; i < this.strikers.length; i++) {

                if (this.strikers[i].player_playing_status_id === 1) {
                    strikePlayers.push(this.strikers[i]);
                }

            }

            return strikePlayers;
        },


    },

    methods: {

        changeStrike() {

            let striker_id;
            let non_striker_id;

            for (let i = 0; i < this.strikers.length; i++) {
                if (this.strikers[i].striker === 1 && this.strikers[i].player_playing_status_id === 1) {
                    striker_id = this.strikers[i].player_id
                } else if(this.strikers[i].player_playing_status_id === 1) {
                    non_striker_id = this.strikers[i].player_id;
                }
            }

            let data = {
                striker_id,
                non_striker_id,
                inning_id: this.storedInning.id
            }

            changeStrike(data).then(response => {
                this.strikers = response.data;
            });


        },
        ...mapMutations({
            vuexSetBattingTeam: 'StartInning/setBattingTeam',
            vuexSetBowlingTeam: 'StartInning/setBowlingTeam',
            vuexSetBatsmen: 'StartInning/setBatsmen',
            vuexSetWicketKeeperID: 'StartInning/setWicketKeeperID',
            vuexSetAllBatsmen: 'StartInning/setAllBatsmen',
            vuexSetBowlers: 'StartInning/setBowlers',
            vuexRemoveBatsmen: 'StartInning/removeBatsmen',
            vuexRemoveBowler: 'StartInning/removeBowler',
            vuexSetStrikers: 'StartInning/setStrikers',
            pushStrikers: 'StartInning/pushStrikers',
            pushBowler: 'StartInning/pushBowler',
            pushBowlers: 'StartInning/pushBowlers',
            vuexSetBowler: 'StartInning/setBowler',
        }),

        ...mapGetters({
            vuexGetFixture: 'LaunchMatch/getFixture',
            vuexGetBattingTeam: 'StartInning/getBattingTeam',
            vuexGetBowlingTeam: 'StartInning/getBowlingTeam',
            vuexGetBatsmen: 'StartInning/getBatsmen',
            vuexGetWicketKeeperID: 'StartInning/getWicketKeeperID',
            vuexGetAllBatsmen: 'StartInning/getAllBatsmen',
            vuexGetBowlers: 'StartInning/getBowlers',
            vuexGetStrikers: 'StartInning/getStrikers',
            vuexGetBowler: 'StartInning/getBowler',
        }),


    }
}