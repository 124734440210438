import API from '../API';

export function initInning(fixture_id) {
    return API.get('launch-match/start-inning/init-inning?fixture_id=' + fixture_id + "&admin=" + true);
}

export function storeInning(data) {
    return API.post('launch-match/inning/store', data);
}

export function storeStriker(data) {
    return API.post('launch-match/striker/store', data);
}

export function storeBowler(data) {
    return API.post('launch-match/two-bowler/store', data);
}


export function removeBowler(data) {
    return API.post('launch-match/two-bowler/delete', data);
}

export function updateBowlingStatus(data) {
    return API.post('launch-match/two-bowler/update-bowling-status', data);
}

export function removeFromCrease(data) {
    return API.post('launch-match/crease-batsmen/remove', data);
}

export function changeStrike(data) {
    return API.post('launch-match/striker/change-striker', data);
}

export function declare(data) {
    return API.post('launch-match/striker/change-playing-status', data);
}

export function loadStrikee(inning_id, bowler_id = 0, bowlers = []) {
    return API.post('launch-match/striker/load-striker?inning_id', {
        'inning_id': inning_id,
        'bowler_id': bowler_id,
        'bowlers': bowlers,
        'admin': true,
    });
}

export function storeEndInning(data) {
    return API.post('launch-match/end-inning/store', data);
}

export function updateMatchStatus(data) {
    return API.post('launch-match/update-match-status', data);
}

export function loadSelectedMatchStatus(fixture_id) {
    return API.get('launch-match/update-match-status/load?fixture_id=' + fixture_id);
}

