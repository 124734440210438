<template>
  <div class="row box">
    <div class="col-12">
      <h6 style="background-color: rgb(172, 200, 223); color: #045BA6; border-radius: 0 0 5px 5px;" class="p-1">
        Match Result
      </h6>
    </div>
    <div class="col-12 col-md-4 form-group">
      <label>
        Result
      </label>
      <select class="form-control" v-model="result.status">
        <option value="1">Completed</option>
        <option value="2">Abandoned</option>
        <option value="3">Draw</option>
        <option value="4">Tied</option>
      </select>
    </div>

    <div class="col-12 col-md-4 form-group" v-if="result.status == 1 || result.status == 3">
      <label>
        Winner Team
      </label>
      <select type="text" class="form-control" v-model="result.winner_team_id">
        <option :value="bowlingTeam.id">{{ bowlingTeam.name }}</option>
        <option :value="battingTeam.id">{{ battingTeam.name }}</option>
      </select>
    </div>

    <div class="col-12 col-md-4 form-group" v-if="result.status == 1 || result.status == 3">
      <label>
        Man of the Match
      </label>
      <select type="text" class="form-control" v-model="result.man_of_the_match_id">
        <option v-for="(player, index) in allBatsmen" :value="player.id" :key="index">{{ player.en_first_name }}
        </option>
        <option v-for="(player, index) in bowlers" :value="player.id" :key="index">{{ player.en_first_name }}
        </option>
      </select>
    </div>

    <div class="col-12 col-md-4 form-group" v-if="result.status == 4">
      <label>
        Supper Over
      </label>
      <select type="text" class="form-control" v-model="result.supper_over">
        <option value="1">Yes</option>
        <option value="0">No</option>
      </select>
    </div>


    <div class="col-12 form-group">
      <label>Closing Statement</label>
      <input placeholder="Closing Statement" class="form-control" v-model="result.closing_statement">
    </div>

    <div class="col-12 form-group">
      <label>Match Report</label>
      <textarea name="" placeholder="Match Report" class="form-control" id="" cols="30" rows="10"
                v-model="result.report"></textarea>
    </div>


    <div class="col-4 mb-2">
      <button class="btn btn-primary" @click="store">Submit</button>
    </div>
  </div>
</template>
<script>
import {StartInning} from "@/mixins/launchMatch/StartInning";
import {get, store} from "@/services/launchMatch/MatchResult";
import {useToast} from "vue-toastification";

export default {
  mixins: [StartInning],
  props: ['defaultStatus'],
  data() {
    return {
      toast: useToast(),
      result: {
        status: this.defaultStatus,
      },
    }
  },

  methods: {
    store() {

      this.result.fixture_id = this.fixture.fixture_id;

      if (this.result.status == 2 || this.result.status == 4) {

        this.result.man_of_the_match_id = '';
        this.result.winner_team_id = '';

      }

      this.toast.info('processing...')
      store(this.result).then(() => {

        this.toast.clear();
      });

    },
    getResult(fixture_id = false) {

      let fixture;

      fixture_id ? fixture = fixture_id : fixture = this.fixture.fixture_id;

      get(fixture).then(response => {


        if (!response.data.status) {
          this.result.status = this.defaultStatus;
        } else {
          this.result = response.data;
        }

      });
    }
  },

  mounted() {
    this.getResult();
  }
}
</script>
<style>

</style>